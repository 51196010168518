import { green, lightBlue, yellow } from '@mui/material/colors'
import { createTheme, PaletteColorOptions } from '@mui/material/styles'

export const uCupRed = '#FF8484'
export const uCupBlue = '#66CFEC'

const uCupPrimary: PaletteColorOptions = {
  ...lightBlue,
  light: '#D4F3FF',
  main: uCupBlue,
  dark: '#3A669B',
}
const uCupSecondary: PaletteColorOptions = {
  ...yellow,
  main: '#FFE471',
}
const uCupSuccess: PaletteColorOptions = {
  ...green,
  main: '#88E283',
}
const uCupWarning: PaletteColorOptions = {
  ...yellow,
  main: '#FFAD61',
}
const uCupError: PaletteColorOptions = {
  ...yellow,
  main: uCupRed,
}

const theme = createTheme({
  shape: {
    borderRadius: 10,
  },
  palette: {
    background: {
      default: '#F3F9FC',
    },
    primary: uCupPrimary,
    secondary: uCupSecondary,
    success: uCupSuccess,
    warning: uCupWarning,
    error: uCupError,
    grey: {
      A100: '#C4C4C4',
      '600': '#777777',
    },
  },
  typography: {
    fontFamily: [
      'Noto Sans TC',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

    h4: {
      // fontWeight: 700,
    },
    h5: {
      // fontWeight: 700,
    },
    h6: {
      // fontWeight: 700,
    },
    body1: {
      // fontWeight: 700,
      letterSpacing: '0.05em',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: 'white',
        },
      },
    },
  },
})

export default theme
