import jwt_decode from 'jwt-decode'
import isJwtExpired from 'src/shared/auth/utils/isJwtExpired'
import { Profile } from './services'
import { removeLocalStorageItem, setLocalStorateItem } from '../localStorage'
import { Liff } from '@line/liff'

const apiUrl = `${process.env.REACT_APP_DATA_PROVIDER_URL}`

const commonHeaders = {
  'Content-Type': 'application/json',
}

const authProvider = {
  // called when the user attempts to log in
  socialLogin: async (socialIdToken) => {
    const action = `users/social_login?provider=${'Line'}`

    const response = await fetch(`${apiUrl}/${action}`, {
      method: 'POST',
      headers: {
        ...commonHeaders,
      },
      body: JSON.stringify({
        id_token: socialIdToken,
      }),
    })
    const resJson = await response.json()
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText + ': ' + resJson.result)
    }

    const { token } = resJson
    setLocalStorateItem('token', token)

    return Promise.resolve(token)
  },

  loginWithLiff: async (liff: Liff) => {
    if (!liff.isLoggedIn()) {
      await liff.login({
        redirectUri: `${window.location.href}`,
      })
      return
    }

    /*
      在外部瀏覽器，liff不會自動更新過期的ID token
      If the user starts the LIFF app in a LIFF browser, the LIFF SDK will get an ID token when you call liff.init().


      If the user starts the LIFF app in an external browser, the LIFF SDK will get an ID token when these steps are satisfied:

        You call liff.login().
        The user logs in.
        You call liff.init().
    */
    const decoded = liff.getDecodedIDToken()
    if (decoded?.exp && isJwtExpired(decoded.exp)) {
      console.warn('LINE ID token expired, re-login')
      liff.logout()
      throw new Error('LINE ID token expired')
    }

    const newIDToken = liff.getIDToken()
    if (!newIDToken) {
      console.error('newIDToken not found')
      throw new Error('newIDToken not found')
    }

    await authProvider.socialLogin(newIDToken)
  },

  getToken: () => {
    let token = localStorage.getItem('token')

    if (token) {
      let decodedToken: Profile | undefined = undefined
      try {
        decodedToken = jwt_decode(token)
      } catch {
        decodedToken = undefined
      }

      if (decodedToken?.exp && isJwtExpired(decodedToken.exp)) {
        token = null
        removeLocalStorageItem('token')
        console.log('token expire', decodedToken?.exp)
      }

      if (decodedToken?.picture_url === undefined) {
        token = null
        removeLocalStorageItem('token')
        console.log('token expire (no picture url)')
      }
    }

    return token
  },

  // called when the user clicks on the logout button
  logout: () => {
    console.warn('logout')
    removeLocalStorageItem('token')
    return Promise.resolve()
  },
  // called when the API returns an error
  /**
   * Fortunately, each time the dataProvider returns an error,
   * react-admin calls the authProvider.checkError() method.
   * If it returns a rejected promise,
   * react-admin calls the authProvider.logout() method immediately,
   * and asks the user to log in again.
   */
  checkError: (error) => {
    if (error.status === 401 || error.status === 403) {
      removeLocalStorageItem('token')
      return Promise.reject('401 or 403')
    }
    return Promise.resolve()
  },

  getProfileFromToken: (token: string) => {
    return jwt_decode(token) as Profile
  },

  getProfile: () => {
    const storedToken = localStorage.getItem('token')
    if (!storedToken) {
      throw Error('no token. please login')
    }
    const profile: Profile = jwt_decode(storedToken)
    return profile
  },
}

export default authProvider
