import React from 'react'

import Avatar from '@mui/material/Avatar'

function OnlineAvatar({ className, src, color = '#FFFFFF', size = 64 }) {
  const padding = 1
  const thickness = 4

  return (
    <div className={className} style={{ position: 'relative' }}>
      <div
        style={{
          borderRadius: '100%',
          position: 'absolute',
          height: size + (padding + thickness) * 2,
          width: size + (padding + thickness) * 2,
          top: -(padding + thickness),
          left: -(padding + thickness),
          border: `${thickness}px solid ${color}`,
        }}
      ></div>
      <div>
        <Avatar src={src} alt="Your head" sx={{ width: size, height: size }} />
      </div>
    </div>
  )
}

export default OnlineAvatar
