export interface LevelDetail {
  name: string
  threshold: number
  level: number
}

export const thresholds = [
  1, 4, 10, 15, 25, 35, 45, 70, 110, 150, 200, 250, 10000,
]
export const names = [
  'F',
  'C-',
  'C',
  'C+',
  'B-',
  'B',
  'B+',
  'A-',
  'A',
  'A+',
  'S-',
  'S',
  'S+',
]

export function makeLevelDetail(level: number): LevelDetail {
  return {
    name: names[level],
    level: level,
    threshold: thresholds[level],
  }
}

export function totalUsageToLevelDetail(
  totalUsage: number | undefined
): LevelDetail {
  if (totalUsage == null) {
    return makeLevelDetail(0)
  }
  for (let i = 0; i < thresholds.length; i++) {
    if (totalUsage < thresholds[i]) {
      return makeLevelDetail(i)
    }
  }
  throw Error(`no map to total usage ${totalUsage}`)
}
