import { Store } from 'src/models/store'

import authProvider from '../auth/authProvider'
import { OpenAPI } from 'src/client'
import { setLocalStorateItem } from '../localStorage'

const url = process.env.REACT_APP_DATA_PROVIDER_URL

// url without /api
OpenAPI.BASE = url?.replace('/api', '') ?? ''
OpenAPI.TOKEN = async () => {
  return authProvider.getToken() ?? ''
}
const highWallFetch = async (apiUrl, options: RequestInit = {}) => {
  if (!options?.headers) {
    options.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }

  const token = authProvider.getToken()

  if (token) {
    options.headers['Authorization'] = `Bearer ${token}`
  }
  const response = await fetch(apiUrl, options)

  try {
    await authProvider.checkError(response)
  } catch (err) {
    console.error(err)
    window.location.reload()
  }
  await checkHighWallError(response)

  return response
}

const checkHighWallError = async (response: Response) => {
  if (response.status < 200 || response.status >= 300) {
    const jsonResponse = await response.json()
    if (
      jsonResponse &&
      jsonResponse?.success === false &&
      jsonResponse?.result
    ) {
      throw { message: jsonResponse.result }
    }
  }
}

const HighWall = {
  async getProducts() {
    const action = '/products'
    const endpoint = url + action

    try {
      const response = await highWallFetch(endpoint)

      const jsonResponse = await response.json()
      if (jsonResponse) {
        return jsonResponse.map((product) => {
          return {
            id: product.id,
            pictureUrl: product.image_url,
            name: product.store_name + product.product_name,
            description: product.product_info,
            cost: product.point,
            inventory: product.inventory,
            endDate: product.deadtime[0],
            active: product.active,
            store_id: product.store_id,
            display_order: product.display_order,
          }
        })
      } else {
        throw new Error('no products in response')
      }
    } catch (error) {
      console.log('Error getting products list: ' + error)
      return []
    }
  },

  async getPoints(userId, bonusType = 'uCup_Point') {
    const action = '/bonuses/available_bonus'
    const params = ``
    const requestUrl = url + action + params

    try {
      const response = await highWallFetch(requestUrl)
      const jsonResponse = await response.json()

      if (jsonResponse.result !== undefined) {
        return jsonResponse.result
      } else if (jsonResponse.error.match('does not exist')) {
        return 0
      } else {
        throw new Error('no point in response!')
      }
    } catch (error) {
      console.log('Error getting points: ' + error)
      return 0
    }
  },

  async bind(studentId: string) {
    const action = `/users/bind_ntu_line`
    const requestUrl = url + action

    try {
      const response = await highWallFetch(requestUrl, {
        method: 'POST',
        body: JSON.stringify({
          ntu_id: studentId,
        }),
      })
      if (response.ok) {
        const jsonResponse = await response.json()
        if (jsonResponse.success) {
          return 'success'
        } else {
          throw new Error('Error binding: ' + jsonResponse.result)
        }
      } else {
        throw new Error('Error binding: ' + response.status)
      }
    } catch (error) {
      console.log('Error: ' + error)
      throw error
    }
  },

  async bindCsghs(nfc_id: string, email: string) {
    const action = `/users/csghs/bind`
    const requestUrl = url + action

    try {
      const response = await highWallFetch(requestUrl, {
        method: 'POST',
        body: JSON.stringify({
          email: email,
          nfc_id: nfc_id,
        }),
      })
      const jsonResponse = await response.json()
      if (jsonResponse.success) {
        return jsonResponse.result
      } else {
        throw jsonResponse.result
      }
    } catch (error) {
      console.log('Error: ' + error)
      throw error
    }
  },

  async getRecordsByUser(userId, provider = 'Normal') {
    const action = '/record/record_by_user'
    const params = `?provider=${provider}&user_id=${userId}`
    const requestUrl = url + action + params

    try {
      const response = await highWallFetch(requestUrl)

      const jsonResponse = await response.json()
      if (jsonResponse) {
        return jsonResponse
      } else {
        throw new Error('no record in response!')
      }
    } catch (error) {
      console.log('Error getting record by user: ' + error)
      throw error
    }
  },

  async getBonusRecordsByUser(userId, bonusType) {
    const action = '/bonuses'
    const params = `?user_id=${userId}&bonus_type=${bonusType}`
    const requestUrl = url + action + params

    try {
      const response = await highWallFetch(requestUrl)

      const jsonResponse = await response.json()
      if (jsonResponse) {
        return jsonResponse
      } else {
        throw new Error('no record in response!')
      }
    } catch (error) {
      console.log('Error getting record by user: ' + error)
      return [
        {
          store_name: '店家名稱',
          cup_type: '類型',
          rent_time: '2020-02-29',
          provider: '途徑',
        },
      ]
    }
  },

  async redeem({
    rewardId,
    bonusType = 'uCup_Point',
    provider,
    userId,
    recordState = 2,
    change,
    inventory = -1,
    info,
  }) {
    const action = '/bonuses/do_bonus_exchange'
    const query = `?product_id=${rewardId}&bonus_type=${bonusType}`
    const endpoint = url + action + query

    try {
      const response = await highWallFetch(endpoint, {
        method: 'POST',
        body: JSON.stringify({
          provider: provider,
          user_id: userId,
          record_state: recordState.toString(),
          change: change,
          inventory: inventory.toString(),
          info: info,
        }),
      })

      const jsonResponse = await response.json()
      if (jsonResponse.success) {
        return jsonResponse
      } else {
        throw { message: jsonResponse.result }
      }
    } catch (error) {
      handleError(error, 'Error redeem')
    }
  },

  async checkRent(userId) {
    const action = `/record/check_rent/${userId}`
    const requestUrl = url + action

    try {
      const response = await highWallFetch(requestUrl)
      const jsonResponse = await response.json()
      if (!jsonResponse) {
        throw new Error('no json')
      }
      return jsonResponse
    } catch (error) {
      return error
    }
  },

  async checkReturn(userId) {
    const action = `/record/check_return/${userId}`

    const requestUrl = url + action
    try {
      const response = await highWallFetch(requestUrl)
      const jsonResponse = await response.json()
      if (!jsonResponse) {
        throw new Error('no json')
      }
      return jsonResponse
    } catch (error) {
      return error
    }
  },

  async getStores() {
    const action = '/stores'
    const endpoint = url + action

    try {
      const response = await highWallFetch(endpoint)
      await checkHighWallError(response)

      const jsonResponse: Store[] = await response.json()
      if (jsonResponse) {
        return jsonResponse
      } else {
        throw new Error('no store in response')
      }
    } catch (error) {
      console.log('Error getting store list: ' + error)
      return []
    }
  },

  async getStore(storeId: number) {
    const action = `/stores/${storeId}`
    const endpoint = url + action

    try {
      const response = await highWallFetch(endpoint)

      const jsonResponse = await response.json()
      if (!jsonResponse) {
        throw new Error('no store in response')
      }
      return jsonResponse
    } catch (error) {
      handleError(error, 'Error  getting store')
    }
  },
  async getLeaderboard(type = 'month') {
    const action = '/record/rent_rank_user'
    const query = `?period_type=${type}`
    const endpoint = url + action + query

    try {
      const response = await highWallFetch(endpoint)
      const jsonResponse = await response.json()
      if (jsonResponse) {
        let limited = jsonResponse.splice(0, 10)
        if (type === 'month') {
          limited = limited.splice(0, 3)
        }
        return limited.map((user) => {
          // TODO: change json
          user.id = user[0]

          user.name = user[2]
          const n = user.name.length
          const stars = '*'.repeat(Math.max(0, n - 2))
          user.name = user.name[0] + stars + user.name[n - 1]
          if (n === 2) {
            user.name = user.name[0] + '*'
          } else if (n === 3) {
            user.name = user.name[0] + '**'
          }
          // if (false) {
          //   user.name = '*'.repeat(Math.max(0, n))
          // }

          user.currentMonthUsage = user[1]
          return {
            id: user.id,
            name: user.name,
            currentMonthUsage: user.currentMonthUsage,
          }
        })
      } else {
        throw new Error('no store in response')
      }
    } catch (error) {
      console.log('Error getting leaderboard: ' + error)
      // if (process.env.NODE_ENV === 'development') {
      //     return fakeData.users;
      // }
      return []
    }
  },

  async sendBindingMail(userId) {
    const action = `/users/${userId}/send_bind_user`
    const query = ``
    const endpoint = url + action + query

    try {
      const response = await highWallFetch(endpoint, {
        method: 'POST',
      })
      const jsonResponse = await response.json()
      if (jsonResponse) {
        return jsonResponse
      } else {
        throw new Error('Error sending email')
      }
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async startVerification(to, channel = 'sms') {
    const action = `/users/start_verification`
    const requestUrl = url + action

    try {
      const response = await highWallFetch(requestUrl, {
        method: 'POST',
        body: JSON.stringify({
          to: to,
          channel: channel,
        }),
      })

      const jsonResponse = await response.json()
      if (!jsonResponse) {
        throw new Error('Error sending sms: no json')
      }
      return jsonResponse
    } catch (error) {
      console.log('Error sending sms: ' + error)
      throw error
    }
  },

  async checkVerification(to, code) {
    const action = `/users/check_verification`
    const requestUrl = url + action

    try {
      const response = await highWallFetch(requestUrl, {
        method: 'POST',
        body: JSON.stringify({
          to: to,
          code: code,
        }),
      })
      const jsonResponse = await response.json()
      if (!jsonResponse) {
        throw new Error('Error sending sms: no json')
      }
      return jsonResponse
    } catch (error) {
      console.log('Error sending sms: ' + error)
      throw error
    }
  },

  async socialLogin(provider, idToken) {
    const action = `/users/social_login?provider=${provider}`
    const requestUrl = url + action

    try {
      const response = await highWallFetch(requestUrl, {
        method: 'POST',
        body: JSON.stringify({
          id_token: idToken,
        }),
      })
      const jsonResponse = await response.json()
      if (jsonResponse) {
        const token = jsonResponse.token
        setLocalStorateItem('token', token)
        return token
      }
    } catch (error) {
      console.log('Error social logining: ' + error)
      throw error
    }
  },

  async doRent(data: any = { cupType: 'uCup' }) {
    const action = '/record/do_rent'
    const requestUrl = url + action

    try {
      const response = await highWallFetch(requestUrl, {
        method: 'POST',
        body: JSON.stringify({
          user_id: data.userId,
          provider: 'Normal',
          store_id: data.storeId,
          num: data.num,
          cup_type: data.cupType,
          cup_id: data.cupId,
          cup_type_id: data.cupTypeId,
          ...data,
        }),
      })

      const jsonResponse = await response.json()
      if (jsonResponse.success) {
        return jsonResponse.result.rent
      } else {
        throw { message: jsonResponse.result }
      }
    } catch (error) {
      handleError(error, 'Error renting')
    }
  },
  async doReturn(data) {
    const action = '/record/do_return'
    const requestUrl = url + action

    try {
      const response = await highWallFetch(requestUrl, {
        method: 'POST',
        body: JSON.stringify({
          user_id: data.userId,
          provider: 'Normal',
          store_id: data.storeId,
          num: data.num,
          cup_id: data.cupId,
        }),
      })

      const jsonResponse = await response.json()
      if (jsonResponse.success) {
        return jsonResponse.result.return
      } else {
        throw { message: jsonResponse.result }
      }
    } catch (error) {
      handleError(error, 'Error returning')
    }
  },
  async uploadReturnImages({
    returnId,
    file,
  }: {
    returnId: string
    file: File
  }) {
    const action = '/record/returns/' + returnId + '/images'
    const requestUrl = url + action

    const formData = new FormData()
    formData.append('file', file, file.name)

    try {
      const response = await highWallFetch(requestUrl, {
        method: 'POST',
        headers: {
          // 'Content-Type': undefined,
          Accept: '*/*',
        },
        body: formData,
      })

      const jsonResponse = await response.json()
      if (jsonResponse.success) {
        return jsonResponse.result.return
      } else {
        throw { message: jsonResponse.result }
      }
    } catch (error) {
      handleError(error, 'Error uploadReturnImages')
    }
  },

  async addCartItem(data: {
    product_id: number
    quantity: number
    note?: string
  }) {
    const action = '/cart'
    const endpoint = url + action

    try {
      const response = await highWallFetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(data),
      })

      const jsonResponse = await response.json()
      if (jsonResponse.success) {
        return jsonResponse.result
      } else {
        throw { message: jsonResponse.result }
      }
    } catch (error) {
      handleError(error, 'Error createOrder')
    }
  },

  async createProductOrder(
    data: {
      phone: string
      receipt_address: string
      email: string
    } = { phone: '', receipt_address: '', email: '' }
  ) {
    const action = '/orders/product'
    const endpoint = url + action

    try {
      const response = await highWallFetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(data),
      })

      const jsonResponse = await response.json()
      if (jsonResponse.success) {
        return jsonResponse.result
      } else {
        throw { message: jsonResponse.result }
      }
    } catch (error) {
      handleError(error, 'Error createOrder')
    }
  },

  async getSubscriptions() {
    const action = '/subscriptions'
    const endpoint = url + action

    try {
      const response = await highWallFetch(endpoint)

      const jsonResponse = await response.json()
      if (jsonResponse.success) {
        return jsonResponse.result
      } else {
        throw { message: jsonResponse.result }
      }
    } catch (error) {
      handleError(error, 'Error getSubscriptions')
    }
  },

  async createSubscriptionOrder(data: {
    subscription_type: string
    store_id: number
    phone: string
    email: string
  }) {
    const action = '/orders/subscription'
    const endpoint = url + action

    try {
      const response = await highWallFetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(data),
      })

      const jsonResponse = await response.json()
      if (jsonResponse.success) {
        return jsonResponse.result
      } else {
        throw { message: jsonResponse.result }
      }
    } catch (error) {
      handleError(error, 'Error createOrder')
    }
  },

  async createSubscriptionExchange(data: any = {}) {
    const action = '/subscription_exchanges'
    const requestUrl = url + action

    try {
      const response = await highWallFetch(requestUrl, {
        method: 'POST',
        body: JSON.stringify({
          subscription_id: data.subscription_id,
        }),
      })

      const jsonResponse = await response.json()
      if (jsonResponse.success) {
        return jsonResponse.result
      } else {
        throw { message: jsonResponse.result }
      }
    } catch (error) {
      handleError(error, 'Error renting')
    }
  },

  async createMachineRent(data: any = { cupType: 'uCup' }) {
    const action = '/record/do_machine_rent'

    const requestUrl = url + action

    try {
      const response = await highWallFetch(requestUrl, {
        method: 'POST',
        body: JSON.stringify({
          user_id: data.userId,
          store_id: data.storeId,
          num: data.num,
        }),
      })

      const jsonResponse = await response.json()
      if (jsonResponse.success) {
        return jsonResponse.result
      } else {
        throw { message: jsonResponse.result }
      }
    } catch (error) {
      handleError(error, 'Error renting')
    }
  },
  async createMachineReturn(data) {
    const action = '/record/do_machine_return'

    const requestUrl = url + action

    try {
      const response = await highWallFetch(requestUrl, {
        method: 'POST',
        body: JSON.stringify({
          user_id: data.userId,
          store_id: data.storeId,
          num: data.num,
        }),
      })

      const jsonResponse = await response.json()
      if (jsonResponse.success) {
        return jsonResponse.result
      } else {
        throw { message: jsonResponse.result }
      }
    } catch (error) {
      handleError(error, 'Error returning')
    }
  },

  async createShareRent(data) {
    const action = `/record/share_rent/${data.userId}`
    const requestUrl = url + action

    try {
      const response = await highWallFetch(requestUrl, {
        method: 'POST',
        body: JSON.stringify({
          share_code: data.shareCode,
          shared_num: data.shareNum,
        }),
      })

      const jsonResponse = await response.json()
      if (jsonResponse.success) {
        return jsonResponse.result
      } else {
        throw { message: jsonResponse.result }
      }
    } catch (error) {
      handleError(error, 'Error share rent')
    }
  },

  async createShareCode(shareNum) {
    const action = '/record/create_share_code'
    const requestUrl = url + action

    try {
      const response = await highWallFetch(requestUrl, {
        method: 'POST',
        body: JSON.stringify({
          share_num: shareNum,
        }),
      })

      const jsonResponse = await response.json()
      if (jsonResponse.success) {
        return jsonResponse.result
      } else {
        throw { message: jsonResponse.result }
      }
    } catch (error) {
      handleError(error, 'Error share code')
    }
  },

  async checkCustosMembership(data: { emp_phone: string }) {
    const action = '/custos/check_custos_membership'
    const endpoint = url + action

    try {
      const response = await highWallFetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(data),
      })

      const jsonResponse = await response.json()
      if (jsonResponse.success) {
        return jsonResponse.result
      } else {
        throw { message: jsonResponse.result }
      }
    } catch (error) {
      handleError(error, 'Error createOrder')
    }
  },
}

function handleError(error: any, log = 'Error') {
  if (error instanceof Error) {
    throw { message: error.message }
  }
  throw error
}
export default HighWall
