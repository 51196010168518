import { Typography, Button } from '@mui/material'

export function GeolocationError({
  onRefresh,
  distance,
}: {
  distance: string
  onRefresh: () => void
}) {
  return (
    <>
      <Typography variant="body2" align="center">
        {distance} km
      </Typography>
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h6" sx={{ fontSize: '1rem' }}>
          請於店家前使用，若有任何疑問請洽客服，謝謝！
        </Typography>
      </div>

      <Button
        variant="outlined"
        fullWidth
        sx={{
          fontWeight: 800,
          marginRight: '10px',
          mb: 2,
        }}
        href="https://www.facebook.com/Cup.Rental.System"
      >
        聯絡客服
      </Button>
      <Button
        size="large"
        variant="contained"
        fullWidth
        style={{
          lineHeight: 'inherit',
          textTransform: 'none',
        }}
        onClick={onRefresh}
      >
        重新定位
      </Button>
    </>
  )
}
