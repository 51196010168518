import { Typography, Button } from '@mui/material'

export function UnknownError(props: React.ComponentProps<typeof SimpleError>) {
  return (
    <SimpleError
      title="發生未知的錯誤"
      msg="請稍後再試，如有需要請聯絡我們！"
      {...props}
    />
  )
}

export function SimpleError({
  title = '發生錯誤',
  msg = '請稍後再試，如有需要請聯絡我們！',
  detail = '',
  onRetry = () => {
    return
  },
}) {
  return (
    <>
      <Typography variant="h6">{title}</Typography>
      <Typography gutterBottom>{msg}</Typography>
      <Typography variant="body2" color="textSecondary">
        {detail}
      </Typography>
      <Button
        fullWidth
        variant="outlined"
        sx={{ my: 1 }}
        href="https://www.facebook.com/Cup.Rental.System"
      >
        聯絡客服
      </Button>
      <Button fullWidth variant="contained" onClick={onRetry}>
        重試
      </Button>
    </>
  )
}
