import { Typography, Grid, Button } from '@mui/material'
import theme from 'src/theme'
import CupImg from './CupImg'
import { MutationType } from './MutationType'

export function MutationError({
  mutationType,
  errorMessage,
  reset,
}: {
  mutationType: MutationType | undefined
  errorMessage: React.ReactNode
  reset: () => void
}) {
  return (
    <>
      <Typography
        variant="h5"
        align="center"
        sx={{ color: theme.palette.error.main }}
      >
        {mutationType}
        失敗
      </Typography>

      <Grid
        sx={{ my: 1 }}
        style={{ flexGrow: 1 }}
        container
        alignItems="center"
        justifyContent="space-around"
      >
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CupImg
            // src={cupType?.image_url}
            mutationType={mutationType}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{
              textAlign: 'center',
              mb: 1,
            }}
          >
            {errorMessage}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              textAlign: 'center',
            }}
          >
            若有任何疑問請洽客服，謝謝
          </Typography>
        </Grid>
      </Grid>

      <Button
        variant="outlined"
        href="https://www.facebook.com/Cup.Rental.System"
        fullWidth
        sx={{ mb: 2 }}
      >
        聯絡客服
      </Button>
      <Button
        size="large"
        variant="contained"
        fullWidth
        style={{
          lineHeight: 'inherit',
          textTransform: 'none',
        }}
        onClick={reset}
      >
        確定
      </Button>
    </>
  )
}
