import { createContext, useContext, useEffect, useState } from 'react'
import liffLib, { Liff } from '@line/liff'

export interface LiffContextProps {
  loading: boolean
  error: unknown
  liff: Liff | undefined
}

export const LiffContext = createContext<LiffContextProps>({
  loading: false,
  error: undefined,
  liff: undefined,
})
export const useLiff = () => useContext(LiffContext)

export function LiffProvider({ children }) {
  const [liff, setLiff] = useState<Liff>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<unknown>(undefined)

  async function loadLiff() {
    try {
      setLoading(true)
      await liffLib.init({
        liffId: process.env.REACT_APP_MY_LIFF_ID ?? '',
      })
      setLiff(liffLib)
    } catch (error) {
      console.error('initializeLiff Error: ', error)
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadLiff()
  }, [])

  return (
    <LiffContext.Provider
      value={{
        liff,
        loading,
        error,
      }}
    >
      {children}
    </LiffContext.Provider>
  )
}
