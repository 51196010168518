import { useCallback, useEffect, useState } from 'react'

type OnSuccess = (obj: { coords: GeolocationCoordinates }) => void

export function useUserLocation({ init } = { init: true }) {
  const [userLocation, setUserLocation] = useState<
    GeolocationCoordinates | undefined
  >(undefined)
  const [userLocationArray, setUserLocationArray] = useState<
    [number, number] | undefined
  >(undefined)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown>(undefined)

  function getUserLocation(
    onSuccess: OnSuccess = (obj: { coords: GeolocationCoordinates }) => {
      return
    }
  ) {
    setLoading(true)
    if (!navigator.geolocation) {
      alert('您的裝置不支援定位')
      console.error('您的裝置不支援定位')
    }
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        setUserLocation(coords)
        setUserLocationArray([coords.latitude, coords.longitude])
        onSuccess({ coords })
        setLoading(false)
      },
      (error) => {
        let message
        if (error.code === error.PERMISSION_DENIED) {
          message = '沒有定位權限，請重新開啟APP並允許定位，或重製瀏覽器設定'
        } else if (error.code === error.POSITION_UNAVAILABLE) {
          message = '定位資訊不正確'
        } else if (error.code === error.TIMEOUT) {
          message = '定位超時'
        } else {
          message = '未知錯誤'
        }
        alert('定位失敗：' + message)
        setError(error)
        console.error(error)
        setLoading(false)
      }
    )
  }

  const getUserLocationIfPermissionGranted = useCallback(
    (onSuccess: OnSuccess = () => undefined) => {
      navigator.permissions &&
        navigator.permissions
          .query({ name: 'geolocation' })
          .then(function (PermissionStatus) {
            if ('granted' === PermissionStatus.state) {
              getUserLocation(onSuccess)
            }
          })
    },
    []
  )

  useEffect(() => {
    if (init) {
      getUserLocationIfPermissionGranted()
    }
  }, [init, getUserLocationIfPermissionGranted])

  return {
    userLocation,
    userLocationArray,
    getUserLocation,
    getUserLocationIfPermissionGranted,
    loading,
    error,
  }
}

export function useWatchUserLocation() {
  const [userLocation, setUserLocation] = useState<number[] | undefined>(
    undefined
  )

  useEffect(() => {
    const id = navigator.geolocation.watchPosition(({ coords }) => {
      setUserLocation([coords.latitude, coords.longitude])
      console.log(coords)
    })
    return () => {
      navigator.geolocation.clearWatch(id)
      console.log('clear')
    }
  }, [])

  return { userLocation }
}
