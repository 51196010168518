import { Typography } from '@mui/material'
import { format } from 'date-fns'
import { useQuery } from 'react-query'
import { RecordService } from 'src/client'
import { useAuth } from 'src/shared/auth/context/authUser'
import { useSnackbar } from 'src/shared/snackbar/context'

export function UncompletedMachineRecords() {
  const { authState } = useAuth()
  const { handleError } = useSnackbar()

  const query = useQuery(
    'uncompleted_machine_return_records',
    RecordService.getUncompletedMachineReturnRecordsApiRecordMachineReturnRecordsUncompletedGet,
    {
      enabled: authState.user !== undefined,
      onError: async (error) => {
        handleError(error)
      },
    }
  )

  const machineReturnRecords = query.data

  if (!(Array.isArray(machineReturnRecords) && machineReturnRecords.length > 0))
    return <></>

  return (
    <div
      style={{
        marginBottom: 32,
      }}
    >
      <Typography variant="h6" color="warning.main">
        未完成還杯紀錄
      </Typography>
      {machineReturnRecords?.map((record) => (
        <div
          key={record.id}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 0',
            borderBottom: '1px solid #e0e0e0',
          }}
        >
          <Typography>
            {format(new Date(record?.create_time), 'yyyy-MM-dd HH:mm')}
          </Typography>
          <Typography>{!record?.cup_lid_returned && '杯蓋未歸還'}</Typography>
          <Typography>{!record?.cup_body_returned && '杯身未歸還'}</Typography>
        </div>
      ))}
    </div>
  )
}
