import { Typography, Box, Button } from '@mui/material'
import { Suspense } from 'react'
import SmallScanner from 'src/shared/ZXingScanner/SmallScanner'

export default function ScanningCups({
  useWebScanner,
  onQrcodeDetected,
  onStartScanClick,
  onCancel,
}: {
  useWebScanner: boolean
  onQrcodeDetected: (qrcode: string) => void
  onStartScanClick: () => void
  onCancel: () => void
}) {
  return (
    <>
      <Typography variant="body1" align="center">
        請掃描杯子上的 QRCode
      </Typography>
      <Typography variant="body1" align="center">
        *請與 QRCode 保持稍遠的距離
      </Typography>

      {useWebScanner ? (
        <Box
          sx={{
            mt: 1,
          }}
          style={{
            background: 'black',
            width: '100%',
            height: '40svh',
            overflow: 'hidden',
          }}
        >
          <Suspense fallback={<></>}>
            <SmallScanner onDetected={onQrcodeDetected} onClose={() => null} />
          </Suspense>
        </Box>
      ) : (
        <div
          style={{
            height: 240,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Button
            size="large"
            variant="contained"
            style={{
              lineHeight: 'inherit',
              textTransform: 'none',
            }}
            onClick={onStartScanClick}
          >
            開始掃描
          </Button>
        </div>
      )}

      <Button
        sx={{ my: 2 }}
        fullWidth
        variant="outlined"
        color="inherit"
        onClick={onCancel}
      >
        取消
      </Button>
    </>
  )
}
