import { Button, styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export default function TakingPhotos({
  onPhotoChange,
}: {
  onPhotoChange: (photo: File) => void
}) {
  return (
    <>
      <Typography variant="body1" align="center">
        請拍照上傳包含杯子與歸還點的照片
      </Typography>

      <div
        style={{
          height: 240,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          開啟相機
          <VisuallyHiddenInput
            type="file"
            accept="image/*"
            capture="environment"
            onChange={async (e) => {
              const file = e.target?.files?.[0]

              if (file) {
                onPhotoChange(file)
              }
            }}
          />
        </Button>
      </div>
    </>
  )
}
