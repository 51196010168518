import { Typography } from '@mui/material'
import addImg from './add.png'
import subImg from './sub.png'

function AddSubInput({
  minNum,
  maxNum,
  value,
  onChange,
}: {
  minNum: number
  maxNum: number
  value: number
  onChange: (change: number) => void
}) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
      }}
    >
      <img
        src={subImg}
        alt="subImg"
        style={{
          objectFit: 'contain',
          cursor: 'pointer',
          WebkitTapHighlightColor: 'transparent',
          filter: value <= minNum ? 'grayscale(1)' : '',
        }}
        width={45}
        height={45}
        onClick={() => onChange(Math.max(value - 1, minNum))}
      ></img>

      <div style={{ display: 'flex', alignItems: 'end' }}>
        <Typography variant="h5">{value}</Typography>
        <Typography style={{ marginLeft: 4, marginBottom: 1 }}>杯</Typography>
      </div>

      <img
        src={addImg}
        alt="addImg"
        style={{
          objectFit: 'contain',
          cursor: 'pointer',
          WebkitTapHighlightColor: 'transparent',
          filter: value >= maxNum ? 'grayscale(1)' : '',
        }}
        width={45}
        height={45}
        onClick={() => onChange(Math.min(value + 1, maxNum))}
      ></img>
    </div>
  )
}

export default AddSubInput
