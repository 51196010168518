export function setLocalStorateItem(key: string, value: any) {
  localStorage.setItem(key, value)

  // notify usehook-ts
  // We dispatch a custom event so every similar useLocalStorage hook is notified
  window.dispatchEvent(new StorageEvent('local-storage', { key }))
}

export function removeLocalStorageItem(key: string) {
  localStorage.removeItem(key)

  // notify usehook-ts
  window.dispatchEvent(new StorageEvent('local-storage', { key }))
}
