/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BindNtuLineRequest } from '../models/BindNtuLineRequest';
import type { BindNtuNFCRequest } from '../models/BindNtuNFCRequest';
import type { BindNtuNFCResponse } from '../models/BindNtuNFCResponse';
import type { CheckVerificationBody } from '../models/CheckVerificationBody';
import type { LogInResponse } from '../models/LogInResponse';
import type { SocialLoginBody } from '../models/SocialLoginBody';
import type { StartVerificationBody } from '../models/StartVerificationBody';
import type { UserBase } from '../models/UserBase';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UsersService {
    /**
     * Get Me
     * @returns UserBase Successful Response
     * @throws ApiError
     */
    public static getMeApiUsersMeGet(): CancelablePromise<UserBase> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/me',
        });
    }
    /**
     * Login
     * @returns LogInResponse Successful Response
     * @throws ApiError
     */
    public static loginApiUsersLoginPost(): CancelablePromise<LogInResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/login',
        });
    }
    /**
     * Social Login
     * Go to LINE
     * https://developers.line.biz/en/docs/line-login/overview/
     *
     * https://qiita.com/kotamatsuoka/items/6351fdf6e3dc37214696
     * https://developers.line.biz/zh-hant/docs/line-login/integrate-line-login/#verify-id-token
     *
     * POST /oauth2/v2.1/verify (ID token)
     * 200 OK (user ID, name, picture URL, email)
     * @returns any Successful Response
     * @throws ApiError
     */
    public static socialLoginApiUsersSocialLoginPost({
        requestBody,
        provider = 'Line',
    }: {
        requestBody: SocialLoginBody,
        provider?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/social_login',
            query: {
                'provider': provider,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bind Ntu Nfc
     * @returns BindNtuNFCResponse Successful Response
     * @throws ApiError
     */
    public static bindNtuNfcApiUsersBindNtuNfcPost({
        requestBody,
    }: {
        requestBody: BindNtuNFCRequest,
    }): CancelablePromise<BindNtuNFCResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/bind_ntu_nfc',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bind Ntu Line
     * @returns any Successful Response
     * @throws ApiError
     */
    public static bindNtuLineApiUsersBindNtuLinePost({
        requestBody,
    }: {
        requestBody: BindNtuLineRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/bind_ntu_line',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Send Bind User
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendBindUserApiUsersNtuIdSendBindUserPost({
        ntuId,
    }: {
        ntuId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/{ntu_id}/send_bind_user',
            path: {
                'ntu_id': ntuId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Start Verification
     * @returns any Successful Response
     * @throws ApiError
     */
    public static startVerificationApiUsersStartVerificationPost({
        requestBody,
    }: {
        requestBody: StartVerificationBody,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/start_verification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Check Verification
     * @returns any Successful Response
     * @throws ApiError
     */
    public static checkVerificationApiUsersCheckVerificationPost({
        requestBody,
    }: {
        requestBody: CheckVerificationBody,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/check_verification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
