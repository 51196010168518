import { Store } from 'src/generated/graphql'

export const getDefaultCupNum = (store) => {
  if (
    Array.isArray(store?.cup_nums) &&
    store.cup_nums.length > 0 &&
    store.default_cup_type_id
  ) {
    const cup_num = store.cup_nums.find(
      (cn) => cn.cup_type_id === store.default_cup_type_id
    )
    return cup_num
  }
}

function haversine(lat1: number, lon1: number, lat2: number, lon2: number) {
  const R = 6371 // Radius of the Earth in kilometers
  const dLat = degreesToRadians(lat2 - lat1)
  const dLon = degreesToRadians(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degreesToRadians(lat1)) *
      Math.cos(degreesToRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c // Distance in kilometers
  return distance
}

function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180)
}

// in meters
export const calcStoreDistance = (
  store: Pick<Store, 'latitude' | 'longitude'>,
  lat: number,
  lng: number
) => {
  // if no store lat or lng
  if (
    !store.latitude ||
    !store.longitude ||
    store.latitude === '0' ||
    store.longitude === '0'
  ) {
    return undefined
  }
  const latFloat = parseFloat(store.latitude)
  const lngFloat = parseFloat(store.longitude)

  return haversine(lat, lng, latFloat, lngFloat) * 1000
}
