import jwt_decode from 'jwt-decode'
import { createContext, useContext, useEffect, useState } from 'react'
import HighWall from 'src/shared/dataProvider/HighWall'
import { parseError } from 'src/utils/utils'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import authProvider from '../authProvider'
import Terms from './Terms'
import { Profile } from '../services'
import { useLocalStorage } from 'usehooks-ts'
import { Liff } from '@line/liff'

export interface AuthContextProps {
  authState: AuthState
  logout: () => Promise<void>
}

export interface AuthState {
  loading: boolean
  error: any
  user: Profile | undefined
  token: string | undefined
}

export const AuthContext = createContext<AuthContextProps>({
  authState: {
    loading: false,
    error: undefined,
    user: undefined,
    token: undefined,
  },
  logout: () => Promise.resolve(),
})

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({
  liff,
  children,
}: {
  liff: Liff
  children: React.ReactNode
}) => {
  const [authState, setAuthState] = useState<AuthState>({
    loading: true,
    error: undefined,
    user: undefined,
    token: undefined,
  })

  const [tokenLocalStorage, _, __] = useLocalStorage('token', null, {
    deserializer: (value) => {
      return value
    },
    serializer: (value) => {
      return value ?? ''
    },
  })
  useEffect(() => {
    // when tokenLocalStorage changes, update authState
    updateStateFromToken()
  }, [tokenLocalStorage])

  const updateStateFromToken = () => {
    console.log('updateStateFromToken')
    const token = authProvider.getToken()
    if (token) {
      setAuthState((prev) => ({
        ...prev,
        user: authProvider.getProfileFromToken(token),
        token: token,
      }))
    } else {
      setAuthState((prev) => ({
        ...prev,
        user: undefined,
        token: undefined,
      }))
    }
  }

  const loginAndUpdateState = async () => {
    try {
      setAuthState((prev) => ({ ...prev, loading: true }))

      if (!authProvider.getToken()) {
        await authProvider.loginWithLiff(liff)
      }
    } catch (error) {
      console.error('Unknown login error: ', error)
      await authProvider.logout()
      setAuthState((prev) => ({
        ...prev,
        error: error,
      }))
    } finally {
      setAuthState((prev) => ({ ...prev, loading: false }))
    }
  }

  useEffect(() => {
    const init = async () => {
      loginAndUpdateState()
    }

    init()
  }, [])

  const profile = authState.user

  return (
    <AuthContext.Provider
      value={{
        authState,
        logout: authProvider.logout,
      }}
    >
      {children}
      {profile !== undefined && profile.terms_agreed === false && (
        <Terms userId={profile?.id} onSuccess={() => loginAndUpdateState()} />
      )}
      <Dialog
        disableEscapeKeyDown
        open={!authState?.loading && !authState?.user?.id}
      >
        <DialogTitle>登入錯誤，請重新登入</DialogTitle>
        <DialogContent>
          <DialogContentText>{parseError(authState.error)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            style={{ color: 'white' }}
            onClick={async () => {
              await authProvider.logout()
              // liff.logout()
              window.location.reload()
            }}
          >
            重新登入
          </Button>
        </DialogActions>
      </Dialog>
    </AuthContext.Provider>
  )
}

export default AuthProvider
