import { parseError } from 'src/utils/utils'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import { Checkbox, FormControlLabel, Link, Typography } from '@mui/material'
import { useMutation, gql } from '@apollo/client'
import { useState } from 'react'
import { CUP_PRICE } from 'src/constants'

const QUERY = gql`
  mutation MyMutation($id: String = "") {
    update_user_by_pk(pk_columns: { id: $id }, _set: { terms_agreed: true }) {
      terms_agreed
    }
  }
`
interface Props {
  userId: string
}
interface Props {
  userId: string
  onSuccess: () => void
}

export const Terms = ({ userId, onSuccess }: Props) => {
  const [agreeMutation, { loading, error, data }] = useMutation(QUERY, {
    // eslint-disable-next-line no-self-assign
    onCompleted: onSuccess,
  })
  const [checked, setChecked] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }
  return (
    <Dialog fullScreen scroll="paper" disableEscapeKeyDown open={true}>
      <img
        style={{
          width: 160,
          height: 160,
          objectFit: 'cover',
          margin: '0 auto',
          marginTop: 36,
        }}
        src="/logo192.png"
        alt="ucup-logo"
      />
      <DialogTitle>歡迎使用 uCup！</DialogTitle>
      <DialogContent>
        <DialogContentText>
          感謝您選擇
          uCup！。在使用我們的服務之前，我們需要您閱讀並同意以下條款和條件。
          <br />
          <br />
          1.可於uCup永續杯平台上購買或借用uCup，詳細流程請參考主頁。
          <br />
          <br />
          2.任何購買之uCup可以在無破損、損毀等不可繼續使用的情況下退回，並獲得購買新uCup的折扣。
          <br />
          <br />
          3.於合作店家借用之uCup，僅可用於裝成合作店家之飲品，不可用做其他用途。
          <br />
          <br />
          4.使用者最多可在同一時間借用3杯，並應於借用後72小時內，至合作店家、自助歸還據點交還借用物，逾期未歸還者將停止使用uCup的權利，帳號將封鎖至歸還uCup後得以解除。
          <br />
          <br />
          5.使用者應以善良管理人之注意使用，且不得全部或一部出借、轉租、分租、頂讓與他人，或造成借用物品損毀。
          若造成借用物品損毀、遺失，每一個杯子賠償 {CUP_PRICE} 元。
          <br />
          <br />
          6.本公司不對任何使用服務有關的間接、附帶、特殊、懲罰性或後果性損害(包含利潤損失、數據丟失、人身傷害或財產損失)相關或其他方面的損害負責，除非完全由本公司發生的狀況導致的。本公司概不負責任何由於您使用、依賴服務或您無法訪問或使用服務而造成的任何損害、責任或損失。本公司概不負責延期或未能在本公司合理控制之外的後果負責。
          <br />
          <br />
          7.本公司保有隨時修正、暫停或終止本平台服務之權利，並保留最終解釋及決定權利。
          <br />
          <br />
          請仔細閱讀我們的
          <Link color="primary" href="https://www.ucup.com.tw/terms">
            使用條款
          </Link>
          ，以確保您理解並同意使用我們的服務。
        </DialogContentText>
      </DialogContent>
      {error && <Typography>{parseError(error)}</Typography>}

      <DialogActions sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        <FormControlLabel
          sx={{ ml: 0 }}
          label="我已閱讀並同意使用條款"
          control={<Checkbox checked={checked} onChange={handleChange} />}
        />
        <Button
          sx={{ mt: 1, mb: 4 }}
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          style={{ color: 'white' }}
          disabled={
            !checked || loading || (data !== null && data !== undefined)
          }
          onClick={() => {
            agreeMutation({ variables: { id: userId } })
          }}
        >
          同意
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Terms
