import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardActionArea from '@mui/material/CardActionArea'
import { useNavigate } from 'react-router-dom'

const PREFIX = 'UnreturnCard'

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  bullet: `${PREFIX}-bullet`,
  title: `${PREFIX}-title`,
  pos: `${PREFIX}-pos`,
  actions: `${PREFIX}-actions`,
}

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minWidth: 275,
    backgroundColor: theme.palette.error.main,
    color: '#ffffff',
    borderRadius: theme.shape.borderRadius,
  },

  [`& .${classes.header}`]: {
    paddingBottom: 0,
  },

  [`& .${classes.bullet}`]: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },

  [`& .${classes.title}`]: {
    // fontSize: 14,
  },

  [`& .${classes.pos}`]: {
    marginBottom: 12,
  },

  [`& .${classes.actions}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    justifyContent: 'flex-end',
    flex: '0 0 auto',
    '& button': { color: theme.palette.secondary.main },
  },
}))

export function OverdueCard() {
  const preventRippleProps = {
    onMouseDown: (event) => event.stopPropagation(),
    onTouchStart: (event) => event.stopPropagation(),
    onFocus: (event) => event.stopPropagation(),
  }

  const navigate = useNavigate()

  return (
    <StyledCard className={classes.root} elevation={4}>
      <CardActionArea>
        <CardHeader className={classes.header} title="您的 uCup 歸還逾期" />
        <CardContent>
          <Typography gutterBottom>
            您的 uCup 使用超過三天，已逾期歸還。帳號已遭鎖定，請盡快歸還 uCup。
            未歸還將無法繼續使用 uCup。
          </Typography>
          <Typography variant="body2" component="p"></Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            sx={{ textTransform: 'none' }}
            size="small"
            onClick={(event) => {
              event.stopPropagation()
              navigate('/lost')
            }}
            {...preventRippleProps}
          >
            遺失 uCup？
          </Button>
          <Button
            size="small"
            onClick={(event) => {
              event.stopPropagation()
              navigate('/stores')
            }}
            {...preventRippleProps}
          >
            查看附近歸還點
          </Button>
        </CardActions>
      </CardActionArea>
    </StyledCard>
  )
}
