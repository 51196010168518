import LinearProgressWithLabel from 'src/components/LinearProgressWithLabel'
import { thresholds, totalUsageToLevelDetail } from 'src/level/levels'

const LevelBar = ({ myTotalUsage, loading }) => {
  const { level } = totalUsageToLevelDetail(myTotalUsage)
  const mn = 0
  const mx = loading ? null : thresholds[level]

  return (
    <LinearProgressWithLabel
      variant={loading ? 'indeterminate' : 'determinate'}
      mn={mn}
      value={myTotalUsage ?? 0}
      mx={mx}
    />
  )
}
export default LevelBar
