import { styled } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'

const PREFIX = 'Loading'

const classes = {
  loading: `${PREFIX}-loading`,
  backdrop: `${PREFIX}-backdrop`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.loading}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const DEBUG = false
function Loading({ debugInfo = '' }) {
  return (
    <Root className={classes.loading}>
      <CircularProgress color="primary" />
      {DEBUG && process.env.NODE_ENV === 'development' && debugInfo && (
        <div>{debugInfo}</div>
      )}
    </Root>
  )
}

export default Loading
