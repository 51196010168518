import { graphql } from 'src/generated'

export const getRentAggregateDocument = graphql(`
  query GetRentAggregate {
    total_usage_aggregate: rent_aggregate(
      where: { rent_type: { _eq: "uCup" } }
    ) {
      aggregate {
        count
      }
    }
    renting_num_aggregate: rent_aggregate(
      where: { rent_type: { _eq: "uCup" }, _not: { returns: {} } }
    ) {
      nodes {
        id
        create_time
        store {
          id
          store_name
          image_url
        }
        cup_id
        rent_type
        returns {
          id
          create_time
        }
        provider
        create_time
        note
      }
      aggregate {
        count
      }
    }
  }
`)
