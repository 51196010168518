/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateBonusBody } from '../models/CreateBonusBody';
import type { DoBonusExchangeBody } from '../models/DoBonusExchangeBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BonusService {
    /**
     * Get Bonus Records
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getBonusRecordsApiBonusesGet({
        bonusType,
        userId,
    }: {
        bonusType: string,
        userId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/bonuses',
            query: {
                'bonus_type': bonusType,
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createApiBonusesPost({
        requestBody,
    }: {
        requestBody: CreateBonusBody,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/bonuses',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getApiBonusesAvailableBonusGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/bonuses/available_bonus',
        });
    }
    /**
     * Do Bonus Exchange
     * @returns any Successful Response
     * @throws ApiError
     */
    public static doBonusExchangeApiBonusesDoBonusExchangePost({
        productId,
        requestBody,
    }: {
        productId: number,
        requestBody: DoBonusExchangeBody,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/bonuses/do_bonus_exchange',
            query: {
                'product_id': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
