import { useQuery } from 'react-query'
import LinearProgressWithLabel from 'src/components/LinearProgressWithLabel'
import HighWall from 'src/shared/dataProvider/HighWall'

import { Avatar, Button, Card, CardActionArea, Grid } from '@mui/material'
import Rating from '@mui/material/Rating'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const PREFIX = 'index'

const classes = {
  iconFilled: `${PREFIX}-iconFilled`,
  iconEmpty: `${PREFIX}-iconEmpty`,
  myCard: `${PREFIX}-myCard`,
}

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.myCard}`]: {
    marginBottom: theme.spacing(0),
    borderRadius: '10px',
    width: '100%',
    maxWidth: '400px',
    minWidth: '350px',
    display: 'flex',
    flexDirection: 'column',

    color: 'white',
  },
}))

export enum SubscriptionType {
  LEVEL_0 = '尊爵',
  LEVEL_1 = '菁英',
  LEVEL_2 = '旗艦',
  LEVEL_3 = '豪華',
}
interface SubscriptionDetail {
  name: string
  color: string
  avatarColor: string
  price: string
  afterDiscont: string
  starNum: number
  descriptions: string[]
  agreement: string
}

const subscriptionDetails: Record<SubscriptionType, SubscriptionDetail> = {
  [SubscriptionType.LEVEL_0]: {
    name: 'uCup/自備 尊爵會員',
    color: '#C4C4C4',
    avatarColor: '#FFFFFF',
    price: '',
    afterDiscont: '',
    starNum: 1,
    descriptions: [
      '+ 借用和歸還uCup各可獲得1點',
      '+ 每月可獲得1點',
      '+ 享有期間限定兌換獎勵',
    ],
    agreement: '',
  },
  [SubscriptionType.LEVEL_1]: {
    name: '75折 菁英會員',
    color: '#8CD6EA',
    avatarColor: '#8CD6EA',
    price: '$99/月',
    afterDiscont: '$49/月',
    starNum: 2,
    descriptions: [
      '+ 自備/uCup皆可兌換, 期限三個月',
      '+ 合作店家享5次全品項75折優惠',
      '+ 兌換完畢可立即續訂, 方案可疊加',
    ],
    agreement:
      '勾選以下的勾選框，即代表您已同意付費49元，升級到uCup菁英會員方案，可至您指定的店家自備環保杯或使用uCup消費享5次75折飲料優惠，<font color="#FF8484">會員種類和店家皆可疊加選取</font>，每新增一個會員種類或店家付費一次<font color="#66CFEC">（ex:菁英會員訂閱一間店$49，二間店$98.....）</font>付費之後使用期限三個月，過期則喪失兌換權利，若提前兌換完畢可立即續訂，付款後若想退費，請洽私訊粉專。',
  },
  [SubscriptionType.LEVEL_2]: {
    name: '75折 旗艦會員',
    color: '#8CD6EA',
    avatarColor: '#8CD6EA',
    price: '$199/月',
    afterDiscont: '$99/月',
    starNum: 3,
    descriptions: [
      '+ 自備/uCup皆可兌換, 期限三個月',
      '+ 合作店家享10次全品項75折優惠',
      '+ 兌換完畢可立即續訂, 方案可疊加',
    ],
    agreement:
      '勾選以下的勾選框，即代表您已同意付費99元，升級到uCup旗艦會員方案，可至您指定的店家自備環保杯或使用uCup消費享10次75折飲料優惠，<font color="#FF8484">會員種類和店家皆可疊加選取</font>，每新增一個會員種類或店家付費一次<font color="#66CFEC">（ex:旗艦會員訂閱一間店$99，二間店$198.....）</font>付費之後使用期限三個月，過期則喪失兌換權利，若提前兌換完畢可立即續訂，付款後若想退費，請洽私訊粉專。',
  },
  [SubscriptionType.LEVEL_3]: {
    name: '半價 豪華會員',
    color: '#FF8484',
    avatarColor: '#FF8484',
    price: '$299/月',
    afterDiscont: '$149/月',
    starNum: 4,
    descriptions: [
      '+ 自備/uCup皆可兌換, 期限三個月',
      '+ 合作店家享10次全品項5折優惠',
      '+ 兌換完畢可立即續訂, 方案可疊加',
    ],
    agreement:
      '勾選以下的勾選框，即代表您已同意付費149元，升級到uCup豪華會員方案，可至您指定的店家自備環保杯或使用uCup消費享10次5折飲料優惠，<font color="#FF8484">會員種類和店家皆可疊加選取</font>，每新增一個會員種類或店家付費一次<font color="#66CFEC">（ex:豪華會員訂閱一間店$149，二間店$298.....）</font>付費之後使用期限三個月，過期則喪失兌換權利，若提前兌換完畢可立即續訂，付款後若想退費，請洽私訊粉專。',
  },
}

export function getSubscriptionDetail(
  subscriptionType?: SubscriptionType
): SubscriptionDetail {
  if (subscriptionType === undefined) {
    return subscriptionDetails[SubscriptionType.LEVEL_0]
  }
  return subscriptionDetails[subscriptionType]
}

const StyledRating = Rating

interface Props {
  subscriptionType?: SubscriptionType
  store?: any
  storeId?: any
  remainings?: number
  buttonText?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onButtonClick: () => any
  onClick?: () => any
  displayButton?: boolean
  displayStore?: boolean
  displayPrice?: boolean
}
export function SubscriptionCard({
  subscriptionType = SubscriptionType.LEVEL_0,
  store,
  storeId,
  remainings,
  onButtonClick,
  onClick,
  buttonText = '敬請期待',
  displayButton = true,
  displayStore = true,
  displayPrice = true,
}: Props) {
  const {
    name: memberName,
    descriptions,
    color,
    starNum,
    price,
    afterDiscont,
  } = getSubscriptionDetail(subscriptionType)

  const getStoreQuery = useQuery(
    ['store', storeId],
    () => HighWall.getStore(storeId ?? 0),
    {
      enabled: store === undefined && storeId !== undefined,
    }
  )

  const storeFinal = store ?? getStoreQuery.data

  return (
    <StyledCard className={classes.myCard} style={{ background: color }}>
      <CardActionArea onClick={onClick} sx={{ padding: 2 }}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <Typography variant="h4">{memberName}</Typography>
            {remainings === undefined ? (
              <StyledRating
                style={{ margin: '8px 0' }}
                value={starNum}
                readOnly
                classes={{
                  iconFilled: classes.iconFilled,
                  iconEmpty: classes.iconEmpty,
                }}
              />
            ) : (
              <div style={{ marginTop: 16, marginBottom: 8, maxWidth: 200 }}>
                <LinearProgressWithLabel
                  variant={'determinate'}
                  mn={0}
                  value={remainings}
                  mx={10}
                  height={16}
                />
              </div>
            )}
          </div>

          {displayPrice && (
            <div>
              <Typography
                variant="h5"
                align="center"
                sx={{
                  textDecoration: 'line-through',
                  textDecorationColor: '#FF0101',
                }}
              >
                {price}
              </Typography>
              <Typography variant="h5" align="center" sx={{ color: '#FF0101' }}>
                {afterDiscont}
              </Typography>
            </div>
          )}
        </div>
        <Grid container>
          <Grid
            item
            xs={8}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ flex: 1, marginBottom: 8 }}>
              {descriptions.map((d, index) => (
                <Typography
                  key={'description' + d}
                  variant="body2"
                  sx={{
                    fontSize: '13px',
                    marginBottom: '0.25rem',
                  }}
                >
                  {d}
                </Typography>
              ))}
            </div>
            {displayButton && (
              <Button
                variant="contained"
                style={{ maxWidth: 160, background: 'white', color: 'black' }}
                onClick={onButtonClick}
              >
                {buttonText}
              </Button>
            )}
          </Grid>

          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            {displayStore && (
              <>
                {storeFinal === undefined && storeId === undefined ? (
                  <div
                    style={{
                      width: 84,
                      height: 84,
                      border: '3px solid #777777',
                      borderRadius: '50%',
                    }}
                  ></div>
                ) : (
                  <Avatar
                    variant="rounded"
                    key={storeFinal?.image_url}
                    src={storeFinal?.image_url}
                    alt="store?.image_url"
                    sx={{ width: 84, height: 84 }}
                  />
                )}
                <Typography
                  align="center"
                  sx={{ color: 'black', marginTop: 1 }}
                  noWrap
                >
                  {storeFinal === undefined
                    ? '選取折價店家'
                    : storeFinal?.store_name}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </CardActionArea>
    </StyledCard>
  )
}
