import imageCompression from 'browser-image-compression'
import { Suspense, useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import BindingAlertCard from 'src/components/BindingAlertCard'
import Loading from 'src/components/Loading/Loading'
import { useAuth } from 'src/shared/auth/context/authUser'
import HighWall from 'src/shared/dataProvider/HighWall'
import { useUserLocation } from 'src/shared/hooks'
import { useQueryParams } from 'src/shared/router-utils'
import { useSnackbar } from 'src/shared/snackbar/context'
import Scanner from 'src/shared/ZXingScanner/ZXingScanner'
import {
  formatHighWallTimestamp,
  getDistanceFromLatLonInKm,
  parseError,
  vibrate,
} from 'src/utils/utils'

import liff from '@line/liff'
import { Avatar, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import { styled, useTheme } from '@mui/material/styles'

import MyProfileCard from '../Index/MyProfileCard'
import Rules from './Rules'
import { RentManyInput, UsersService } from 'src/client'
import Rents from './Rents'
import CupImg from './CupImg'
import { MutationType } from './MutationType'
import ConfirmDialog from './ConfirmDIalog'
import { GeolocationError } from './geolocation'
import { getDefaultCupNum } from '../stores/services'
import { useRentStatus } from 'src/rent/hooks'
import { isVerified } from 'src/shared/auth/services'
import { MutationError } from './MutationError'
import { UncompletedMachineRecords } from '../rents/UncompletedMachineRecords'
import TakingPhotos from './steps/TakingPhotos'
import ChoosingMutationType from './steps/ChoosingMutationType'
import ChoosingNumber from './steps/ChoosingNumber'
import ScanningCups from './steps/ScanningCups'

const Root = styled('div')(({ theme }) => ({}))

const ALLOWED_DISTANCE = 0.1

enum Mode {
  None,
  MutationByUser,
  MutationByMachine,
  MutationByReturnBox,
}
enum MutationByUserStep {
  ChoosingMutationType = 'ChoosingMutationType',
  SelfConfirming = 'SelfConfirming',
  ScanningCupsOrChoosingNumber = 'ScanningCupsOrChoosingNumber',
  ScanningCups = 'ScanningCups',
  ChoosingNumber = 'ChoosingNumber',
  TakingPhotos = 'TakingPhotos',
  Confirming = 'Confirming',
  Result = 'Result',
}
enum MutationByMachineStep {
  Confirming = 'Confirming',
  WaitingForMachine = 'WaitingForMachine',
  Result = 'Result',
}
enum MutationByReturnBoxStep {
  ChoosingNumber = 'ChoosingNumber',
  Confirming = 'Confirming',
  Result = 'Result',
}
enum StoreType {
  Store = '一般店家',
  Machine = '彼得機',
  ReturnBox = '簡易歸還箱',
  OnlyRentStore = '借用專門店',
}

function RentOrReturn() {
  const navigate = useNavigate()
  const theme = useTheme()
  const { handleNotification, handleError } = useSnackbar()
  const { authState } = useAuth()
  const profile = authState.user
  const verified = profile !== undefined && isVerified(profile)

  const { getRentAggregateResult, rentingNum, totalUsage, isOverDue } =
    useRentStatus({
      skip: profile === undefined,
    })
  const onSuccess = getRentAggregateResult.refetch

  function handleMutationSuccess(type: MutationType, mode: Mode) {
    handleNotification(`${type}成功！趕緊看看點數，兌換優惠吧！`)
    onSuccess()
    if (mode === Mode.MutationByReturnBox) {
      setStep(MutationByReturnBoxStep.Result)
    } else {
      setStep(MutationByUserStep.Result)
    }
  }

  const [mode, setMode] = useState<Mode>(Mode.None)
  const [mutationStoreId, setMutationStoreId] = useState<number | undefined>(
    undefined
  )
  const getMutationStoreQuery = useQuery(
    ['store', mutationStoreId],
    () => HighWall.getStore(mutationStoreId ?? 0),
    {
      enabled: mutationStoreId !== undefined,
      onSuccess: (data) => {
        if (data?.geolocation_needed) {
          getUserLocation()
        }
      },
    }
  )
  const query = useQueryParams()
  function parseStoreId(storeIdStr: unknown) {
    if (
      typeof storeIdStr !== 'string' ||
      !/^[0-9]*$/.test(storeIdStr) ||
      parseInt(storeIdStr) < 0
    ) {
      throw Error('店家 id 格式不符: ' + storeIdStr)
    }
    return parseInt(storeIdStr)
  }
  useEffect(() => {
    if (query.get('storeId')) {
      onUrlDetected(window.location.href)
    }
  }, [query])

  const [scannerOpen, setScannerOpen] = useState(false)

  const [step, setStep] = useState<
    MutationByUserStep | MutationByMachineStep | MutationByReturnBoxStep
  >(MutationByUserStep.ChoosingMutationType)

  const [mutationType, setMutationType] = useState<MutationType | undefined>(
    undefined
  )

  const [rentOrReturnNumber, setRentOrReturnNumber] = useState(1)

  const getMeQuery = useQuery(
    ['user', profile?.id],
    UsersService.getMeApiUsersMeGet,
    {
      enabled: !!profile?.id,
    }
  )
  const rentLimit = getMeQuery?.data?.rent_limit ?? 3
  const maxRentableNum = rentLimit - (rentingNum ?? 0)
  const maxReturnableNum = rentingNum ?? 0
  const maxNum =
    mutationType === MutationType.RENT ? maxRentableNum : maxReturnableNum
  const minRentableNum = maxRentableNum >= 1 ? 1 : 0
  const minReturnableNum = maxReturnableNum >= 1 ? 1 : 0
  const minNum =
    mutationType === MutationType.RENT ? minRentableNum : minReturnableNum

  const doRentMutation = useMutation<any, unknown, any>(HighWall.doRent, {
    onSuccess: () => handleMutationSuccess(MutationType.RENT, mode),
  })
  const doReturnMutation = useMutation<any, unknown, any>(HighWall.doReturn, {
    onSuccess: (data) => {
      handleMutationSuccess(MutationType.RETURN, mode)
      if (returnPhotoNeeded) {
        uploadReturnImages.mutate({ returnId: data.id, file: returnImage })
      }
    },
  })
  const doRentSelfMutation = useMutation<any, unknown, any>(HighWall.doRent, {
    onSuccess: () => handleMutationSuccess(MutationType.SELF, mode),
  })
  const createMachineRentMutation = useMutation<any, unknown, any>(
    HighWall.createMachineRent,
    {
      onSuccess: () => handleMachineMutationSuccess(MutationType.RENT),
    }
  )
  const createMachineReturnMutation = useMutation<any, unknown, any>(
    HighWall.createMachineReturn,
    {
      onSuccess: () => handleMachineMutationSuccess(MutationType.RETURN),
    }
  )

  const [checkRentData, setCheckRentData] = useState<any>(undefined)
  const [checkReturnData, setCheckReturnData] = useState<any>(undefined)

  const uploadReturnImages = useMutation<any, unknown, any>(
    HighWall.uploadReturnImages,
    {
      onError: (e) => handleError(e),
    }
  )
  const machineMutate = () => {
    try {
      if (profile?.id === undefined) {
        throw Error('登入異常')
      }
      if (mutationStoreId === undefined) {
        throw Error('未知錯誤: 未選擇店家')
      }
      const data = {
        userId: profile.id,
        storeId: mutationStoreId,
        num: rentOrReturnNumber,
      }
      if (mutationType === MutationType.RENT) {
        createMachineRentMutation.mutate(data)
      } else if (mutationType === MutationType.RETURN) {
        createMachineReturnMutation.mutate(data)
      } else {
        throw Error('未知錯誤: 未選擇借還')
      }
    } catch (error) {
      setLocalError(parseError(error))
    }
  }
  function handleMachineMutationSuccess(type: MutationType) {
    setStep(MutationByMachineStep.WaitingForMachine)
  }
  const [checkingCounter, setCheckingCounter] = useState(0)
  const INTERVAL = 3000
  const DURATION = 50000
  const isCheckingTimeout = checkingCounter * INTERVAL > DURATION
  const [checkingError, setCheckingError] = useState<unknown>(undefined)
  useEffect(() => {
    if (
      liff &&
      profile &&
      step === MutationByMachineStep.WaitingForMachine &&
      !isCheckingTimeout
    ) {
      const checkSuccess = setInterval(async () => {
        setCheckingCounter((prev) => prev + 1)

        const checkRent = await HighWall.checkRent(profile.id)
        const checkReturn = await HighWall.checkReturn(profile.id)
        let successMutation: MutationType | undefined
        if (checkRent.success === true) {
          if (checkRent.result.success === false) {
            setCheckingError(checkRent.result.result.error_message)
          } else {
            successMutation = MutationType.RENT
            setCheckRentData(checkRent.result.result)
          }
        }
        if (checkReturn.success === true) {
          if (checkReturn.result.success === false) {
            setCheckingError(checkReturn.result.result.error_message)
          } else {
            successMutation = MutationType.RETURN
            setCheckReturnData(checkReturn?.result?.result?.return)
          }
        }

        if (successMutation) {
          handleMutationSuccess(successMutation, mode)
        }
      }, INTERVAL)
      return () => clearInterval(checkSuccess)
    }
  }, [profile, step, checkingCounter, isCheckingTimeout])

  const [localError, setLocalError] = useState<unknown>(undefined)

  const isLineScanOk = liff && liff.scanCode !== undefined && liff.isInClient()
  useEffect(() => {
    async function lineScan() {
      if (scannerOpen && isLineScanOk && liff.scanCode) {
        liff
          .scanCode()
          .then((result) => {
            const r = result?.value ?? ''
            if (r === 'null') {
              reset()
              return
            }
            onQrcodeDetected(r)
          })
          .catch((error) => {
            setLocalError(parseError(error))
          })
      }
    }
    lineScan()
  }, [scannerOpen, isLineScanOk])

  const [cupId, setCupId] = useState<string | undefined>(undefined)
  const [returnImage, setReturnImage] = useState<File | undefined>(undefined)
  const photoUrl = returnImage && URL.createObjectURL(returnImage)
  const onQrcodeDetected = (newDetectResult: string) => {
    try {
      vibrate()
    } catch {
      console.log('no vibrate')
    }

    if (step === MutationByUserStep.ScanningCups) {
      if (mutationType === undefined) {
        throw Error('未知錯誤：請選擇租借或歸還')
      }
      setCupId(newDetectResult)
      setStep(MutationByUserStep.Confirming)
    } else {
      onUrlDetected(newDetectResult)
    }
    setScannerOpen(false)
  }

  const [storeType, setStoreType] = useState(StoreType.Store)
  const onUrlDetected = (newDetectResult: string) => {
    try {
      let storeIdStr: string | null = null
      try {
        const url = new URL(newDetectResult)
        storeIdStr = url.searchParams.get('storeId')
      } catch (error) {
        throw Error('QR Code 格式不符: ' + newDetectResult)
      }
      const sid = parseStoreId(storeIdStr)
      setMutationStoreId(sid)
    } catch (error) {
      setLocalError(parseError(error))
    }

    const newMutationType = new URL(newDetectResult).searchParams.get(
      'mutationType'
    )
    if (
      newMutationType &&
      Object.values<string>(MutationType).includes(newMutationType)
    ) {
      try {
        setMutationType(newMutationType as MutationType)
      } catch (error) {
        setLocalError(parseError(error))
      }
    }

    const newStoreType =
      new URL(newDetectResult).searchParams.get('storeType') ??
      (newMutationType ? StoreType.Machine : StoreType.Store)
    if (
      newStoreType &&
      Object.values<string>(StoreType).includes(newStoreType)
    ) {
      try {
        setStoreType(newStoreType as StoreType)
        switch (newStoreType) {
          case StoreType.Store: {
            setMode(Mode.MutationByUser)
            setStep(MutationByUserStep.ChoosingMutationType)
            break
          }
          case StoreType.OnlyRentStore: {
            setMode(Mode.MutationByUser)
            setStep(MutationByUserStep.ChoosingMutationType)
            break
          }
          case StoreType.Machine: {
            setMode(Mode.MutationByMachine)
            setStep(MutationByMachineStep.Confirming)
            break
          }
          case StoreType.ReturnBox: {
            setMode(Mode.MutationByReturnBox)
            setStep(MutationByReturnBoxStep.ChoosingNumber)
            setMutationType(MutationType.RETURN)
            break
          }
          default:
            break
        }
      } catch (error) {
        setLocalError(parseError(error))
      }
    }
  }
  const mutate = (type: MutationType, mode: Mode) => {
    try {
      if (profile?.id === undefined) {
        throw Error('登入異常')
      }
      if (mutationStoreId === undefined) {
        throw Error('未知錯誤: 未選擇店家')
      }
      const data = {
        userId: profile.id,
        storeId: mutationStoreId,
        num: rentOrReturnNumber,
        cupId: cupId,

        rents: shouldUseRentMany ? rentsToSend : undefined,
      }
      if (type === MutationType.RENT) {
        doRentMutation.mutate(data)
      } else if (type === MutationType.RETURN) {
        doReturnMutation.mutate(data)
      } else if (type === MutationType.SELF) {
        doRentSelfMutation.mutate({ ...data, cupType: 'Self' })
      } else {
        throw Error('未知錯誤: 未選擇借還')
      }
    } catch (error) {
      setLocalError(parseError(error))
    }
  }

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const reset = () => {
    doRentMutation.reset()
    doReturnMutation.reset()
    doRentSelfMutation.reset()
    createMachineRentMutation.reset()
    createMachineReturnMutation.reset()
    setCheckingCounter(0)
    setLocalError(undefined)
    // setStep(MutationByUserStep.ChoosingMutationType)
    setScannerOpen(false)
    setCupId(undefined)
    // setMode(Mode.None)
    setMutationType(undefined)
    // setMutationStoreId(undefined)
    setCheckingCounter(0)
    setRentOrReturnNumber(1)
    // setCupTypeId(undefined)
    resetRents(store)
    onUrlDetected(window.location.href)
  }

  const {
    userLocation,
    loading: userLocationLoading,
    error: userLocationError,
    getUserLocation,
  } = useUserLocation({
    init: false,
  })
  const distance = getDistanceFromLatLonInKm(
    userLocation?.latitude,
    userLocation?.longitude,
    getMutationStoreQuery.data?.latitude,
    getMutationStoreQuery.data?.longitude
  )

  const store = getMutationStoreQuery.data
  const returnPhotoNeeded = store?.return_photo_needed ?? false

  const rentToSend = {
    id: 'cupId or return',
    num: rentOrReturnNumber,
    photoUrl: photoUrl,
    cupId: cupId,
  }

  const [rentsFormInput, setRentsFormInput] = useState<RentManyInput[]>([])
  const rentsToSend = rentsFormInput.filter((rent) => rent.num && rent.num > 0)

  const shouldUseRentMany =
    mutationType === MutationType.RENT && rentsToSend.length > 0

  const totalNumOfRents = rentsToSend.reduce(
    (sum, rent) => sum + (rent?.num ?? 0),
    0
  )
  const totalNumResult = shouldUseRentMany
    ? totalNumOfRents
    : rentOrReturnNumber
  const cupNumsWithCupType = store?.cup_nums?.filter(
    (cn) => cn.cup_type_id !== null
  )
  // convert cupNumsWithCupType to object with cup_type_id as key
  const cupTypes = cupNumsWithCupType?.reduce((acc, cn) => {
    if (cn.cup_type_id) {
      acc[cn.cup_type_id] = cn.cup_type
    }
    return acc
  }, {})

  const getCupNumsToChoose = useCallback((store) => {
    if (store?.cup_nums?.length > 1) {
      return store?.cup_nums?.filter((cn) => cn.cup_type_id !== null)
    } else {
      return store?.cup_nums
    }
  }, [])
  const cupNumsToChoose = getCupNumsToChoose(store)
  const defaultCupNum = getDefaultCupNum(store)

  const resetRents = useCallback(
    (store) => {
      const cupNumsToChoose = getCupNumsToChoose(store)
      if (cupNumsToChoose) {
        const newInput = cupNumsToChoose.map((cn) => ({
          cup_type_id: cn.cup_type_id,
          num: 0,
        }))
        if (newInput.length === 1) {
          newInput[0].num = 1
        }
        setRentsFormInput(newInput)
      }
    },
    [getCupNumsToChoose]
  )

  useEffect(() => {
    resetRents(store)
  }, [resetRents, store])

  if (profile !== undefined && !verified) {
    return <BindingAlertCard onToBind={() => navigate('/bind')} />
  }

  return (
    <Root>
      <Box sx={{ mb: 4 }}>
        <MyProfileCard
          rentingNum={rentingNum}
          myTotalUsage={totalUsage}
          myTotalUsageLoading={getRentAggregateResult.loading}
          bound={verified}
          onBindClick={() => navigate('/bind')}
          onBindingStatusClick={() => navigate('')}
          hideLevelBar={true}
        />

        <ListItem alignItems="center" sx={{ mt: 1 }}>
          <ListItemAvatar>
            <Avatar
              variant="rounded"
              sx={{
                width: 48,
                height: 48,
                borderRadius: 99999,
                ml: 1.5,
                marginRight: 2,
              }}
              alt={store?.store_name}
              key={store?.store_name}
              src={store?.image_url}
            />
          </ListItemAvatar>

          <Typography variant="body1">{store?.store_name}</Typography>
        </ListItem>

        {(() => {
          if (
            authState.loading ||
            getMutationStoreQuery.isLoading ||
            doRentMutation.isLoading ||
            doReturnMutation.isLoading ||
            doRentSelfMutation.isLoading ||
            userLocationLoading
          ) {
            return (
              <>
                <Loading />
              </>
            )
          } else if (
            // getMeQuery.error ||
            authState.error ||
            getMutationStoreQuery.isError ||
            doRentMutation.isError ||
            doReturnMutation.isError ||
            doRentSelfMutation.isError ||
            createMachineRentMutation.isError ||
            createMachineReturnMutation.isError ||
            isCheckingTimeout ||
            checkingError ||
            localError ||
            userLocationError ||
            store?.active === false
          ) {
            return (
              <MutationError
                mutationType={mutationType}
                reset={reset}
                errorMessage={
                  <>
                    {/* {getMeQuery.error && parseError(getMeQuery.error)} */}
                    {store?.active === false && '店家已封存'}
                    {authState.error && parseError(authState.error)}
                    {getMutationStoreQuery.isError &&
                      parseError(getMutationStoreQuery.isError)}
                    {userLocationError && parseError(userLocationError)}
                    {localError && localError}
                    {checkingError && checkingError}
                    {doRentMutation.error && parseError(doRentMutation.error)}
                    {doReturnMutation.error &&
                      parseError(doReturnMutation.error)}
                    {doRentSelfMutation.error &&
                      parseError(doRentSelfMutation.error)}
                    {createMachineRentMutation.error &&
                      parseError(createMachineRentMutation.error)}
                    {createMachineReturnMutation.error &&
                      parseError(createMachineReturnMutation.error)}
                    {isCheckingTimeout && '設備連線逾時'}
                  </>
                }
              />
            )
          } else
            return (
              <>
                {mode === Mode.MutationByUser &&
                  step === MutationByUserStep.ChoosingMutationType && (
                    <>
                      {store?.geolocation_needed &&
                      (isNaN(distance) || distance > ALLOWED_DISTANCE) ? (
                        <GeolocationError
                          distance={distance.toFixed(2)}
                          onRefresh={() => getUserLocation()}
                        />
                      ) : (
                        <ChoosingMutationType
                          isOverDue={isOverDue}
                          rentDisabled={
                            isOverDue || store?.rent_available === false
                          }
                          onRentClick={() => {
                            if (maxRentableNum === 0) {
                              handleError('超過可借上限，請先歸還杯子')
                              return
                            }
                            setMutationType(MutationType.RENT)
                            if (store?.cup_id_rent_verified) {
                              setStep(MutationByUserStep.ScanningCups)
                            } else {
                              setStep(MutationByUserStep.ChoosingNumber)
                            }
                          }}
                          returnDisabled={
                            storeType === StoreType.OnlyRentStore ||
                            store?.return_available === false
                          }
                          onReturnClick={() => {
                            if (maxReturnableNum === 0) {
                              handleError('沒有可歸還的 uCup')
                              return
                            }
                            setMutationType(MutationType.RETURN)
                            if (store?.cup_id_return_verified) {
                              setStep(MutationByUserStep.ScanningCups)
                            } else {
                              setStep(MutationByUserStep.ChoosingNumber)
                            }
                          }}
                          onCancel={() => navigate('/')}
                        />
                      )}
                    </>
                  )}
                {((mode === Mode.MutationByUser &&
                  step === MutationByUserStep.ChoosingNumber) ||
                  (mode === Mode.MutationByReturnBox &&
                    step === MutationByReturnBoxStep.ChoosingNumber)) && (
                  <ChoosingNumber
                    rentingNum={rentingNum}
                    minNum={minNum}
                    maxNum={maxNum}
                    mutationType={mutationType}
                    cupNumsToChoose={cupNumsToChoose}
                    onNext={() => {
                      if (
                        returnPhotoNeeded &&
                        mutationType === MutationType.RETURN
                      ) {
                        setStep(MutationByUserStep.TakingPhotos)
                      } else {
                        setStep(MutationByUserStep.Confirming)
                      }
                    }}
                    onCancel={reset}
                    rentOrReturnNumber={rentOrReturnNumber}
                    onRentOrReturnNumberChange={(value) =>
                      setRentOrReturnNumber(value)
                    }
                    totalNumOfRents={totalNumOfRents}
                    rentsFormInput={rentsFormInput}
                    setRentsFormInput={setRentsFormInput}
                  />
                )}
                <ConfirmDialog
                  mutationType={mutationType}
                  open={confirmDialogOpen}
                  onClose={() => setConfirmDialogOpen(false)}
                  onOk={() => {
                    if (mutationType === undefined) {
                      throw Error('未知錯誤：請選擇租借或歸還')
                    }
                    setConfirmDialogOpen(false)
                    mutate(mutationType, mode)
                  }}
                />

                {(mode === Mode.MutationByUser ||
                  mode === Mode.MutationByReturnBox) &&
                  step === MutationByUserStep.ScanningCups && (
                    <ScanningCups
                      useWebScanner={!isLineScanOk}
                      onQrcodeDetected={onQrcodeDetected}
                      onStartScanClick={() => setScannerOpen(true)}
                      onCancel={reset}
                    />
                  )}
                {(mode === Mode.MutationByUser ||
                  mode === Mode.MutationByReturnBox) &&
                  step === MutationByUserStep.TakingPhotos && (
                    <TakingPhotos
                      onPhotoChange={async (file) => {
                        const compressed = await imageCompression(file, {
                          fileType: 'image/jpeg',
                          initialQuality: 0.8,
                          maxSizeMB: 1,
                          maxWidthOrHeight: 1920,
                          useWebWorker: true,
                          preserveExif: true,
                        })
                        setReturnImage(compressed)
                        setStep(MutationByUserStep.Confirming)
                      }}
                    />
                  )}
                {(mode === Mode.MutationByUser ||
                  mode === Mode.MutationByReturnBox) &&
                  step === MutationByUserStep.Confirming && (
                    <>
                      <Typography variant="h6" align="center">
                        {'確認' + mutationType}
                      </Typography>

                      {mode === Mode.MutationByReturnBox && (
                        <>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            align="center"
                          >
                            如歸還於歸還箱，請打開蓋子, 分開投入杯身杯蓋
                          </Typography>
                        </>
                      )}

                      <Rents
                        cupTypes={cupTypes}
                        mutationType={mutationType}
                        rents={shouldUseRentMany ? rentsToSend : [rentToSend]}
                      />

                      {mutationType === MutationType.RENT && <Rules />}

                      <Typography
                        variant="body1"
                        align="center"
                        sx={{ color: 'info.main' }}
                        gutterBottom
                      >
                        *按下確認後即無法取消
                      </Typography>
                      <Button
                        sx={{ my: 1 }}
                        size="large"
                        variant="contained"
                        fullWidth
                        style={{
                          lineHeight: 'inherit',
                          textTransform: 'none',
                        }}
                        onClick={() => {
                          if (mutationType === undefined) {
                            throw Error('未知錯誤：請選擇租借或歸還')
                          }
                          mutate(mutationType, mode)
                        }}
                        disabled={
                          createMachineRentMutation.isLoading ||
                          createMachineReturnMutation.isLoading
                        }
                      >
                        確認
                        {mutationType}
                      </Button>

                      <Button
                        sx={{ my: 1 }}
                        color="error"
                        variant="outlined"
                        fullWidth
                        onClick={reset}
                      >
                        取消
                      </Button>
                    </>
                  )}
                {mode === Mode.MutationByMachine &&
                  (step === MutationByMachineStep.Confirming ||
                    step === MutationByMachineStep.WaitingForMachine) && (
                    <>
                      <UncompletedMachineRecords />
                      <Grid
                        style={{ flexGrow: 1 }}
                        sx={{ my: 1 }}
                        container
                        alignItems="center"
                        justifyContent="space-around"
                      >
                        <Grid
                          item
                          xs={4}
                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                          <CupImg
                            src={defaultCupNum?.cup_type?.image_url}
                            cupTypeName={defaultCupNum?.cup_type?.name}
                            mutationType={mutationType}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="h6" align="center" gutterBottom>
                            {rentOrReturnNumber}個uCup
                          </Typography>

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'center',
                              visibility:
                                step === MutationByMachineStep.WaitingForMachine
                                  ? 'visible'
                                  : 'hidden',
                            }}
                          >
                            <Loading />
                            <Typography
                              variant="body1"
                              align="center"
                              sx={{ paddingLeft: 1 }}
                            >
                              等待設備{mutationType}中...
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>

                      <Button
                        size="large"
                        variant="contained"
                        fullWidth
                        style={{
                          lineHeight: 'inherit',
                          textTransform: 'none',
                        }}
                        onClick={() => {
                          machineMutate()
                        }}
                        disabled={
                          createMachineRentMutation.isLoading ||
                          createMachineReturnMutation.isLoading ||
                          step === MutationByMachineStep.WaitingForMachine
                        }
                      >
                        確認
                        {mutationType}
                      </Button>
                    </>
                  )}

                {(step === MutationByUserStep.Result ||
                  step === MutationByMachineStep.Result ||
                  step === MutationByReturnBoxStep.Result) && (
                  <>
                    <Typography
                      variant="h5"
                      align="center"
                      sx={{ color: theme.palette.success.main }}
                    >
                      {mutationType}
                      成功
                    </Typography>
                    <Typography align="center" color="textSecondary">
                      感謝您使用 uCup
                    </Typography>

                    {(() => {
                      const data =
                        doRentMutation.data ??
                        doReturnMutation.data ??
                        doRentSelfMutation.data ??
                        checkRentData ??
                        checkReturnData
                      if (mode === Mode.MutationByUser && !data) {
                        return <p>error: no data</p>
                      }
                      return (
                        <Grid
                          sx={{ my: 1 }}
                          style={{ flexGrow: 1 }}
                          container
                          alignItems="center"
                          justifyContent="space-around"
                        >
                          <Rents
                            cupTypes={cupTypes}
                            mutationType={mutationType}
                            rents={
                              shouldUseRentMany ? rentsToSend : [rentToSend]
                            }
                          />
                          <Grid
                            item
                            xs={12}
                            sx={{
                              mt: 1,
                              mb: 2,
                              px: 1,
                            }}
                          >
                            <div style={{ display: 'flex' }}>
                              <Typography
                                sx={{ width: 100 }}
                                color="textSecondary"
                              >
                                使用時間
                              </Typography>
                              <Typography align="center">
                                {formatHighWallTimestamp(
                                  data?.create_time,
                                  'yyyy/MM/dd HH:mm'
                                )}
                              </Typography>
                            </div>
                            <div style={{ display: 'flex' }}>
                              <Typography
                                sx={{ width: 100 }}
                                color="textSecondary"
                              >
                                獲得點數
                              </Typography>
                              <Typography align="center">
                                {totalNumResult}點
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      )
                    })()}

                    <Button
                      size="large"
                      variant="contained"
                      fullWidth
                      style={{
                        lineHeight: 'inherit',
                        textTransform: 'none',
                      }}
                      onClick={() => {
                        reset()
                        navigate('/')
                      }}
                    >
                      確定
                    </Button>
                  </>
                )}
              </>
            )
        })()}
      </Box>
      <Dialog
        PaperProps={{ style: { background: 'black' } }}
        fullScreen
        open={scannerOpen && !isLineScanOk}
        onClose={reset}
      >
        <Suspense fallback={<></>}>
          <Scanner
            facingMode="environment"
            onDetected={onQrcodeDetected}
            onClose={reset}
          />
        </Suspense>
      </Dialog>
    </Root>
  )
}

export default RentOrReturn
