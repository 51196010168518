import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardActionArea from '@mui/material/CardActionArea'

const PREFIX = 'BindingAlertCard'

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  bullet: `${PREFIX}-bullet`,
  title: `${PREFIX}-title`,
  pos: `${PREFIX}-pos`,
  actions: `${PREFIX}-actions`,
}

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minWidth: 275,
    backgroundColor: theme.palette.error.main,
    color: '#ffffff',
    borderRadius: theme.shape.borderRadius,
  },

  [`& .${classes.header}`]: {
    paddingBottom: 0,
  },

  [`& .${classes.bullet}`]: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },

  [`& .${classes.title}`]: {
    // fontSize: 14,
  },

  [`& .${classes.pos}`]: {
    marginBottom: 12,
  },

  [`& .${classes.actions}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    justifyContent: 'flex-end',
    flex: '0 0 auto',
    '& button': { color: theme.palette.secondary.main },
  },
}))

function BindingAlertCard({ onToBind }) {
  function handleToBind(event) {
    event.stopPropagation()
    onToBind()
  }

  const preventRippleProps = {
    onMouseDown: (event) => event.stopPropagation(),
    onTouchStart: (event) => event.stopPropagation(),
    onFocus: (event) => event.stopPropagation(),
  }

  return (
    <StyledCard className={classes.root} elevation={4}>
      <CardActionArea onClick={handleToBind}>
        <CardHeader className={classes.header} title="你的帳號尚未驗證！" />
        <CardContent>
          <Typography gutterBottom>
            未驗證將無法借用 uCup，趕快點擊此卡片來綁定手機或信箱，享受 uCup
            的便利時尚吧！
          </Typography>
          <Typography variant="body2" component="p"></Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button size="small" onClick={handleToBind} {...preventRippleProps}>
            前往綁定
          </Button>
        </CardActions>
      </CardActionArea>
    </StyledCard>
  )
}

export default BindingAlertCard
