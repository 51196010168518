import { Typography } from '@mui/material'
import { CUP_PRICE } from 'src/constants'

function Rules() {
  return (
    <div style={{ margin: '0 16px', textAlign: 'justify' }}>
      <Typography gutterBottom color="textSecondary">
        1. 請於借用後72小時內歸還至合作店家、自助歸還站
      </Typography>
      <Typography gutterBottom color="textSecondary">
        2. 逾期未歸還將停權帳號，直至歸還後得以解除
      </Typography>
      <Typography gutterBottom color="textSecondary">
        3. 若杯子損毀、遺失，每一個杯子賠償 {CUP_PRICE} 元
      </Typography>
    </div>
  )
}

export default Rules
