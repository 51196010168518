import jwt_decode from 'jwt-decode'

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { removeLocalStorageItem } from '../localStorage'

const authLink = setContext((_, defaultContext) => {
  const token = localStorage.getItem('token')
  if (token === null) {
    return {}
  }
  try {
    const profile = jwt_decode(token) as any
    if (!profile?.role) {
      throw Error('Client Error: no role in jwt')
    }
    return {
      headers: {
        ...defaultContext?.headers,
        'x-hasura-role': profile.role,
        Authorization: `Bearer ` + token,
      },
    }
  } catch (error) {
    removeLocalStorageItem('token')
    throw Error('Client Error: jwt_decode error' + JSON.stringify(error))
  }
})

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HASURA_URL,
})

const myApolloClientWithAuth = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

export default myApolloClientWithAuth
