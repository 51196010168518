import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import OnlineAvatar from 'src/components/OnlineAvatar'
import LevelBar from 'src/level/LevelBar'
import { totalUsageToLevelDetail } from 'src/level/levels'
import { useAuth } from 'src/shared/auth/context/authUser'
import { getSubscriptionDetail } from 'src/subscription'
import { parseError } from 'src/utils/utils'

import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import custosImg from './custos-logo.jpg'
import Box from '@mui/material/Box'

const PREFIX = 'MyProfileCard'

const classes = {
  myCard: `${PREFIX}-myCard`,
  myRankRow: `${PREFIX}-myRankRow`,
  avatar: `${PREFIX}-avatar`,
  progressBarBackground: `${PREFIX}-progressBarBackground`,
  progressBar: `${PREFIX}-progressBar`,
  statistics: `${PREFIX}-statistics`,
  titleRow: `${PREFIX}-titleRow`,
  nameRow: `${PREFIX}-nameRow`,
  bind: `${PREFIX}-bind`,
  bound: `${PREFIX}-bound`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.myCard}`]: {
    marginBottom: theme.spacing(0),
    padding: theme.spacing(2),
    borderRadius: '10px',
  },

  [`& .${classes.myRankRow}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  [`& .${classes.avatar}`]: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },

  [`& .${classes.progressBarBackground}`]: {
    backgroundColor: theme.palette.grey.A100,
  },

  [`& .${classes.progressBar}`]: {
    backgroundColor: theme.palette.secondary.main,
  },

  [`& .${classes.statistics}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    '& div': {
      textAlign: 'center',
    },
  },

  [`& .${classes.titleRow}`]: {
    display: 'flex',
    '& h6': {
      minWidth: 50,
    },
  },

  [`& .${classes.bind}`]: {
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.secondary.main,
  },

  [`& .${classes.bound}`]: {
    border: 'none',
    borderRadius: 4,
    backgroundColor: theme.palette.success.main,
    color: 'white',
  },
}))

function MyProfileCard({
  rentingNum,
  currentSubscription = undefined,
  myTotalUsage,
  myTotalUsageLoading,
  bound,
  onBindClick,
  onBindingStatusClick,
  onShareClick = undefined,
  hideLevelBar = false,
}: {
  rentingNum: number | undefined
  myTotalUsage: number | undefined
  myTotalUsageLoading: boolean
  bound: any
  onBindClick: any
  onBindingStatusClick: any
  currentSubscription?: any
  onShareClick?: any
  hideLevelBar?: boolean
}) {
  const theme = useTheme()
  const { authState } = useAuth()
  const { user: profile, loading: authLoading, error } = authState

  const loading = myTotalUsageLoading || authLoading
  const { name } = totalUsageToLevelDetail(myTotalUsage)

  // const rentingNum = records?.filter(
  //   (record) => record?.rent_type !== 'Self' && record?.returns?.length === 0
  // ).length

  const sub_type = currentSubscription?.sub_type

  function renderBindingBadge() {
    if (bound === null || bound === undefined || loading) {
      return (
        <button className={classes.bind} style={{ visibility: 'hidden' }}>
          未綁定
        </button>
      )
    }

    return (
      <Root>
        {!bound ? (
          <button className={classes.bind} onClick={onBindClick}>
            未綁定
          </button>
        ) : (
          <button className={classes.bound} onClick={onBindingStatusClick}>
            已綁定
          </button>
        )}
      </Root>
    )
  }

  return (
    <Root>
      <Paper className={classes.myCard}>
        <div className={classes.myRankRow}>
          <div>
            <OnlineAvatar
              className={classes.avatar}
              size={
                getSubscriptionDetail(sub_type).avatarColor === '#FFFFFF'
                  ? 72
                  : 64
              }
              src={loading ? '' : profile?.picture_url}
              color={getSubscriptionDetail(sub_type).avatarColor}
            />
          </div>
          <div className={classes.statistics}>
            <div>
              <Typography variant="h5">{loading ? '.' : `${name}`}</Typography>
              <Typography variant="body2">永續評等</Typography>
            </div>
            <NavLink to="/rents">
              <div>
                <Typography variant="h5">
                  {loading || error ? (
                    '.'
                  ) : (
                    <span id="my-total-usage">{myTotalUsage}</span>
                  )}
                </Typography>
                <Typography variant="body2">總減量數</Typography>
              </div>
            </NavLink>
            <NavLink to="/rents">
              <div
                style={{
                  color:
                    rentingNum && rentingNum > 0
                      ? theme.palette.primary.main
                      : '#000000',
                }}
              >
                <Typography variant="h5">
                  {loading || rentingNum === undefined ? (
                    '.'
                  ) : (
                    <>{rentingNum}</>
                  )}
                </Typography>
                <Typography variant="body2">使用中</Typography>
              </div>
            </NavLink>
          </div>
        </div>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: onShareClick ? 0 : 1,
            // height: '48px',
          }}
        >
          <Typography
            variant="body1"
            style={{
              minWidth: '80px',
              paddingLeft: '4px',
            }}
          >
            {error && '登入失敗：' + parseError(error)}
            {loading ? <Skeleton /> : profile?.username}
          </Typography>

          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <CustosStatus custos_phone={profile?.custos_phone} />
            {renderBindingBadge()}
            {onShareClick && (
              <IconButton
                aria-label="share"
                color="inherit"
                onClick={onShareClick}
                size="large"
              >
                <ShareOutlinedIcon />
              </IconButton>
            )}
          </div>
        </Box>

        {!hideLevelBar && (
          <LevelBar myTotalUsage={myTotalUsage} loading={loading} />
        )}

        {/* 訂閱制取消 */}
        {/* <div style={{ marginTop: '8px' }}>
          <Typography
            variant="body1"
            style={{
              minWidth: '80px',
              paddingLeft: '4px',
            }}
          >
            {loading ? <Skeleton /> : getSubscriptionDetail(sub_type).name}
          </Typography>
        </div> */}
      </Paper>
    </Root>
  )
}

function CustosStatus({ custos_phone }: { custos_phone?: string }) {
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)
  return (
    <>
      {custos_phone && (
        <IconButton
          size="large"
          onClick={() => {
            setOpen(true)
          }}
        >
          <img src={custosImg} alt="custos logo" width={24} height={24} />
        </IconButton>
      )}

      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Custos 綁定狀態</DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <ListItemText primary={'會員手機'} secondary={custos_phone} />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="inherit">
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default MyProfileCard
