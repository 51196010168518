/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_upload_return_images_api_record_returns__return_id__images_post } from '../models/Body_upload_return_images_api_record_returns__return_id__images_post';
import type { CompensateBody } from '../models/CompensateBody';
import type { CompensateResponse } from '../models/CompensateResponse';
import type { CompensationRequestBody } from '../models/CompensationRequestBody';
import type { CompensationRequestResponse } from '../models/CompensationRequestResponse';
import type { DoRentBody } from '../models/DoRentBody';
import type { DoRentResponse } from '../models/DoRentResponse';
import type { DoReturnBody } from '../models/DoReturnBody';
import type { DoReturnResponse } from '../models/DoReturnResponse';
import type { GetIsRentingResponse } from '../models/GetIsRentingResponse';
import type { MachineCupNotifyBody } from '../models/MachineCupNotifyBody';
import type { MachineReturnRecordSchema } from '../models/MachineReturnRecordSchema';
import type { MachineReturnResultBody } from '../models/MachineReturnResultBody';
import type { MachineReturnResultResponse } from '../models/MachineReturnResultResponse';
import type { Provider } from '../models/Provider';
import type { RentGetManySchema } from '../models/RentGetManySchema';
import type { ShareCodeBody } from '../models/ShareCodeBody';
import type { ShareCodeSchema } from '../models/ShareCodeSchema';
import type { ShareRentBody } from '../models/ShareRentBody';
import type { UpdateCompenstationResultBody } from '../models/UpdateCompenstationResultBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RecordService {
    /**
     * Get Many
     * @returns ShareCodeSchema Successful Response
     * @throws ApiError
     */
    public static getManyApiRecordShareCodesGet(): CancelablePromise<Array<ShareCodeSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/record/share_codes',
        });
    }
    /**
     * Create Share Code
     * @returns DoRentResponse Successful Response
     * @throws ApiError
     */
    public static createShareCodeApiRecordCreateShareCodePost({
        requestBody,
    }: {
        requestBody: ShareCodeBody,
    }): CancelablePromise<DoRentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/record/create_share_code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Share Rent
     * @returns DoRentResponse Successful Response
     * @throws ApiError
     */
    public static shareRentApiRecordShareRentUserIdPost({
        userId,
        requestBody,
    }: {
        userId: string,
        requestBody: ShareRentBody,
    }): CancelablePromise<DoRentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/record/share_rent/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Uncompleted Machine Return Records
     * @returns MachineReturnRecordSchema Successful Response
     * @throws ApiError
     */
    public static getUncompletedMachineReturnRecordsApiRecordMachineReturnRecordsUncompletedGet(): CancelablePromise<Array<MachineReturnRecordSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/record/machine_return_records/uncompleted',
        });
    }
    /**
     * Get Rents Statistics
     * /record/rents/statistics
     * Public api for users and stores to see statistics so far.
     *
     * :return:        {
         * total: int,
         * Self: int,
         * uCup: int
         * }
         * @returns any Successful Response
         * @throws ApiError
         */
        public static getRentsStatisticsApiRecordRentsStatisticsGet(): CancelablePromise<any> {
            return __request(OpenAPI, {
                method: 'GET',
                url: '/api/record/rents/statistics',
            });
        }
        /**
         * Get Rent By User
         * @returns RentGetManySchema Successful Response
         * @throws ApiError
         */
        public static getRentByUserApiRecordRecordByUserGet({
            provider,
            userId,
        }: {
            provider: string,
            userId: string,
        }): CancelablePromise<Array<RentGetManySchema>> {
            return __request(OpenAPI, {
                method: 'GET',
                url: '/api/record/record_by_user',
                query: {
                    'provider': provider,
                    'user_id': userId,
                },
                errors: {
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Do Rent
         * @returns DoRentResponse Successful Response
         * @throws ApiError
         */
        public static doRentApiRecordDoRentPost({
            requestBody,
        }: {
            requestBody: DoRentBody,
        }): CancelablePromise<DoRentResponse> {
            return __request(OpenAPI, {
                method: 'POST',
                url: '/api/record/do_rent',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    400: `Bad Request`,
                    404: `Not Found`,
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Do Return
         * @returns DoReturnResponse Successful Response
         * @throws ApiError
         */
        public static doReturnApiRecordDoReturnPost({
            requestBody,
        }: {
            requestBody: DoReturnBody,
        }): CancelablePromise<DoReturnResponse> {
            return __request(OpenAPI, {
                method: 'POST',
                url: '/api/record/do_return',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    400: `Bad Request`,
                    404: `Not Found`,
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Upload Return Images
         * @returns any Successful Response
         * @throws ApiError
         */
        public static uploadReturnImagesApiRecordReturnsReturnIdImagesPost({
            returnId,
            formData,
        }: {
            returnId: string,
            formData: Body_upload_return_images_api_record_returns__return_id__images_post,
        }): CancelablePromise<any> {
            return __request(OpenAPI, {
                method: 'POST',
                url: '/api/record/returns/{return_id}/images',
                path: {
                    'return_id': returnId,
                },
                formData: formData,
                mediaType: 'multipart/form-data',
                errors: {
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Check Is Renting
         * @returns GetIsRentingResponse Successful Response
         * @throws ApiError
         */
        public static checkIsRentingApiRecordIsRentingGet({
            userIdFromProvider,
            provider,
        }: {
            userIdFromProvider: string,
            provider: Provider,
        }): CancelablePromise<GetIsRentingResponse> {
            return __request(OpenAPI, {
                method: 'GET',
                url: '/api/record/is_renting',
                query: {
                    'user_id_from_provider': userIdFromProvider,
                    'provider': provider,
                },
                errors: {
                    400: `Bad Request`,
                    401: `Unauthorized`,
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Check Is Returnable
         * @returns GetIsRentingResponse Successful Response
         * @throws ApiError
         */
        public static checkIsReturnableApiRecordIsReturnablePost({
            requestBody,
        }: {
            requestBody: DoReturnBody,
        }): CancelablePromise<GetIsRentingResponse> {
            return __request(OpenAPI, {
                method: 'POST',
                url: '/api/record/is_returnable',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    400: `Bad Request`,
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Check Rent
         * @returns any Successful Response
         * @throws ApiError
         */
        public static checkRentApiRecordCheckRentUserIdGet({
            userId,
        }: {
            userId: string,
        }): CancelablePromise<any> {
            return __request(OpenAPI, {
                method: 'GET',
                url: '/api/record/check_rent/{user_id}',
                path: {
                    'user_id': userId,
                },
                errors: {
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Check Return
         * @returns any Successful Response
         * @throws ApiError
         */
        public static checkReturnApiRecordCheckReturnUserIdGet({
            userId,
        }: {
            userId: string,
        }): CancelablePromise<any> {
            return __request(OpenAPI, {
                method: 'GET',
                url: '/api/record/check_return/{user_id}',
                path: {
                    'user_id': userId,
                },
                errors: {
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Rent Rank User
         * @returns any Successful Response
         * @throws ApiError
         */
        public static rentRankUserApiRecordRentRankUserGet({
            periodType = 'month',
        }: {
            periodType?: string,
        }): CancelablePromise<any> {
            return __request(OpenAPI, {
                method: 'GET',
                url: '/api/record/rent_rank_user',
                query: {
                    'period_type': periodType,
                },
                errors: {
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Get First Users In Store
         * @returns any Successful Response
         * @throws ApiError
         */
        public static getFirstUsersInStoreApiRecordGetFirstUsersInStoreGet({
            dateGte,
            dateLte,
            storeId,
        }: {
            dateGte: string,
            dateLte: string,
            storeId?: number,
        }): CancelablePromise<any> {
            return __request(OpenAPI, {
                method: 'GET',
                url: '/api/record/get_first_users_in_store',
                query: {
                    'date_gte': dateGte,
                    'date_lte': dateLte,
                    'store_id': storeId,
                },
                errors: {
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Get Rent Count By Day
         * @returns any Successful Response
         * @throws ApiError
         */
        public static getRentCountByDayApiRecordRentCountByDayGet({
            dateGte,
            dateLte,
        }: {
            dateGte: string,
            dateLte: string,
        }): CancelablePromise<any> {
            return __request(OpenAPI, {
                method: 'GET',
                url: '/api/record/rent_count_by_day',
                query: {
                    'date_gte': dateGte,
                    'date_lte': dateLte,
                },
                errors: {
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Machine Cup Num Notify
         * @returns DoRentResponse Successful Response
         * @throws ApiError
         */
        public static machineCupNumNotifyApiRecordMachineCupNumNotifyPost({
            requestBody,
        }: {
            requestBody: MachineCupNotifyBody,
        }): CancelablePromise<DoRentResponse> {
            return __request(OpenAPI, {
                method: 'POST',
                url: '/api/record/machine_cup_num_notify',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    400: `Bad Request`,
                    404: `Not Found`,
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Do Machine Rent
         * @returns DoRentResponse Successful Response
         * @throws ApiError
         */
        public static doMachineRentApiRecordDoMachineRentPost({
            requestBody,
        }: {
            requestBody: DoRentBody,
        }): CancelablePromise<DoRentResponse> {
            return __request(OpenAPI, {
                method: 'POST',
                url: '/api/record/do_machine_rent',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    400: `Bad Request`,
                    404: `Not Found`,
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Machine Rent Result
         * @returns DoRentResponse Successful Response
         * @throws ApiError
         */
        public static machineRentResultApiRecordMachineRentResultPost({
            requestBody,
        }: {
            requestBody: DoRentBody,
        }): CancelablePromise<DoRentResponse> {
            return __request(OpenAPI, {
                method: 'POST',
                url: '/api/record/machine_rent_result',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    400: `Bad Request`,
                    404: `Not Found`,
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Do Machine Return
         * @returns DoRentResponse Successful Response
         * @throws ApiError
         */
        public static doMachineReturnApiRecordDoMachineReturnPost({
            requestBody,
        }: {
            requestBody: DoReturnBody,
        }): CancelablePromise<DoRentResponse> {
            return __request(OpenAPI, {
                method: 'POST',
                url: '/api/record/do_machine_return',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    400: `Bad Request`,
                    404: `Not Found`,
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Machine Return Result
         * @returns MachineReturnResultResponse Successful Response
         * @throws ApiError
         */
        public static machineReturnResultApiRecordMachineReturnResultPost({
            requestBody,
        }: {
            requestBody: MachineReturnResultBody,
        }): CancelablePromise<MachineReturnResultResponse> {
            return __request(OpenAPI, {
                method: 'POST',
                url: '/api/record/machine_return_result',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    400: `Bad Request`,
                    404: `Not Found`,
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Compensate
         * @returns CompensateResponse Successful Response
         * @throws ApiError
         */
        public static compensateApiRecordCompensatePost({
            requestBody,
        }: {
            requestBody: CompensateBody,
        }): CancelablePromise<CompensateResponse> {
            return __request(OpenAPI, {
                method: 'POST',
                url: '/api/record/compensate',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Create Compensation Request
         * @returns CompensationRequestResponse Successful Response
         * @throws ApiError
         */
        public static createCompensationRequestApiRecordCompensationRequestPost({
            requestBody,
        }: {
            requestBody: CompensationRequestBody,
        }): CancelablePromise<CompensationRequestResponse> {
            return __request(OpenAPI, {
                method: 'POST',
                url: '/api/record/compensation_request',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    422: `Validation Error`,
                },
            });
        }
        /**
         * Update Compensation Result
         * @returns CompensateResponse Successful Response
         * @throws ApiError
         */
        public static updateCompensationResultApiRecordCompensationResultPost({
            requestBody,
        }: {
            requestBody: UpdateCompenstationResultBody,
        }): CancelablePromise<CompensateResponse> {
            return __request(OpenAPI, {
                method: 'POST',
                url: '/api/record/compensation_result',
                body: requestBody,
                mediaType: 'application/json',
                errors: {
                    422: `Validation Error`,
                },
            });
        }
    }
