import { Button, Grid, TextField, Typography } from '@mui/material'
import { useRentStatus } from 'src/rent/hooks'
import { useAuth } from 'src/shared/auth/context/authUser'
import { useTappay } from 'src/tappay/hooks'
import { useEffect, useState } from 'react'
import { CUP_PRICE } from 'src/constants'
import RecordList from '../rents/RecordList'
import { RecordService } from 'src/client'
import { useMutation } from 'react-query'
import { useSnackbar } from 'src/shared/snackbar/context'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import isEmail from 'validator/lib/isEmail'
import { isTWPhone } from 'src/utils/utils'
import Loading from 'src/components/Loading/Loading'
import { useQueryParams } from 'src/shared/router-utils'

interface IFormInputs {
  name: string
  phone: string
  email: string
}
export function Lost() {
  const { authState } = useAuth()
  const profile = authState.user
  const { getRentAggregateResult, renting } = useRentStatus({
    skip: profile === undefined,
  })

  const { status, update, TPDirect } = useTappay()

  const { handleError, handleNotification } = useSnackbar()

  // get rec_trade_id from query string
  // if rec_trade_id, handleNotification('支付成功！')

  const query = useQueryParams()
  const rec_trade_id = query.get('rec_trade_id')
  useEffect(() => {
    if (rec_trade_id) {
      handleNotification('支付成功！')
      getRentAggregateResult.refetch()
      setSelectedIds([])
    }
  }, [rec_trade_id])

  const {
    handleSubmit,
    control,
    reset,

    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: {},
  })

  const mutation = useMutation(
    RecordService.createCompensationRequestApiRecordCompensationRequestPost,
    {
      onSuccess: async (data) => {
        window.location.href = data.result.payment_url

        // non-3d way:

        // handleNotification('支付成功！')
        // getRentAggregateResult.refetch()
        // setSelectedIds([])
      },
      onError: async (error) => {
        handleError(error)
      },
    }
  )
  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    TPDirect.card.getPrime(function (result) {
      if (result.status !== 0) {
        handleError('付款失敗：getPrime failed')
      }
      const prime = result.card.prime

      mutation.mutate({
        requestBody: {
          prime: prime,
          rent_ids: selectedIds,
          phone_number: data.phone,
          email: data.email,
          name: data.name,
        },
      })
      console.log('getPrime success: ' + prime)
    })
  }

  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const total = selectedIds.length * CUP_PRICE

  return (
    <div>
      <Typography
        sx={{
          textAlign: 'justify',
        }}
      >
        若遺失 uCup
        杯身或杯蓋，造成無法正常歸還，可線上支付賠償費，系統會為您解除鎖定。若無法線上付款，或有其他相關問題，請聯絡客服，
        將會有專人回覆您遇到的問題！
        <br />
      </Typography>
      <Button
        fullWidth
        variant="outlined"
        sx={{ mt: 2, fontWeight: 800, marginRight: '10px' }}
        href="https://www.facebook.com/Cup.Rental.System"
      >
        聯絡客服
      </Button>
      <Typography
        variant="h6"
        sx={{
          mt: 2,
        }}
      >
        請選擇要賠償的紀錄
      </Typography>
      <div
        style={{
          margin: '0 -8px',
          marginTop: '-8px',
        }}
      >
        <RecordList
          selection
          checkedIds={selectedIds}
          onChange={(ids) => setSelectedIds(ids)}
          showShareButton={false}
          records={
            renting &&
            renting.map((r) => ({
              id: r.id,
              store_name: r?.store?.store_name,
              store_image_url: r?.store?.image_url,
              cup_id: r.cup_id,
              rent_type: r.rent_type,
              returns: r.returns,
              provider: r.provider,
              create_time: r.create_time,
              note: r.note,
            }))
          }
          userId={profile?.id ?? ''}
        />
      </div>
      <div>
        <div
          style={{
            marginTop: '8px',
            visibility: selectedIds.length > 0 ? 'visible' : 'hidden',
          }}
        >
          {selectedIds.length} * ${CUP_PRICE}/杯，共 ${total}
        </div>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          付款方式
        </Typography>
        <div
          style={{
            visibility: selectedIds.length > 0 ? 'visible' : 'hidden',
            marginBottom: '96px',
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="持卡人姓名"
                      variant="outlined"
                      required
                      error={errors.name !== undefined}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="phone"
                  control={control}
                  rules={{ required: true, validate: isTWPhone }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="手機號碼"
                      variant="outlined"
                      required
                      placeholder="0912345678"
                      error={errors.phone !== undefined}
                      helperText={errors.phone ? '請輸入正確的手機號碼' : ''}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true, validate: isEmail }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="電子信箱"
                      variant="outlined"
                      required
                      placeholder="abc@gmail.com"
                      error={errors.email !== undefined}
                      helperText={errors.email ? '請輸入正確的電子信箱' : ''}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <br />
            <br />
            <div id="cardview-container"></div>

            <br />
            <br />
            <Button
              variant="contained"
              fullWidth
              disabled={!update?.canGetPrime}
              type="submit"
            >
              送出
            </Button>
            <br />
            <br />
            {mutation.isLoading && <Loading />}

            {update?.hasError && <p>請檢查您的信用卡資訊</p>}
            {hasError(update?.status.number) && <p>請檢查您的信用卡號碼</p>}
            {hasError(update?.status.expiry) && <p>請檢查您的信用卡期限</p>}
            {hasError(update?.status.ccv) && <p>請檢查您的信用卡 cvc</p>}
          </form>
        </div>
      </div>
    </div>
  )
}

function hasError(num: number | undefined) {
  return num !== undefined && num > 0
}
