import { Grid, Typography } from '@mui/material'
import AddSubInput from './AddSubInput'
import CupImg from './CupImg'

export function RentInput({
  mutationType,
  cupImageUrl = undefined,
  cupTypeName = undefined,
  value,
  onChange,
  minNum,
  maxNum,
}) {
  return (
    <div>
      <Grid
        style={{ flexGrow: 1 }}
        sx={{ my: 1 }}
        container
        alignItems="center"
        justifyContent="space-around"
      >
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CupImg src={cupImageUrl} mutationType={mutationType} />
          <Typography align="center">{cupTypeName}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="center"></Typography>

          <br />

          <AddSubInput
            value={value}
            onChange={onChange}
            minNum={minNum}
            maxNum={maxNum}
          />
        </Grid>
      </Grid>
    </div>
  )
}
