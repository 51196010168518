import { Grid, Typography } from '@mui/material'
import CupImg from './CupImg'
import { MutationType } from './MutationType'

export default function Rents({
  rents,
  cupTypes,
  mutationType,
}: {
  rents: {
    id?: string
    num?: number
    photoUrl?: string
    cupId?: string
    cup_type_id?: string
    cup_id?: string
  }[]
  mutationType?: MutationType
  cupTypes: {
    [key: string]: {
      name: string
      image_url: string
    }
  }
}) {
  return (
    <>
      {rents.map((record) => (
        <Grid
          key={record.cup_type_id ?? record.id ?? 'no-key'}
          style={{ flexGrow: 1 }}
          sx={{ my: 1 }}
          container
          alignItems="center"
          justifyContent="space-around"
        >
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <CupImg
              width={70}
              height={112}
              src={
                record?.cup_type_id && cupTypes[record.cup_type_id]?.image_url
              }
              mutationType={mutationType}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" align="center" gutterBottom>
              {record.cup_type_id ? cupTypes[record.cup_type_id].name : 'uCup'}{' '}
              * {record.num}
            </Typography>

            {record.cupId && (
              <Typography variant="h6" align="center" gutterBottom>
                {/* 只顯示末五碼 */}#{record.cupId.slice(-5)}
              </Typography>
            )}
            {record.photoUrl && (
              <img
                src={record.photoUrl}
                alt="使用照片"
                width={48}
                height={48}
                style={{
                  display: 'block',
                  margin: '0 auto',

                  objectFit: 'cover',
                }}
              />
            )}

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                // visibility:
                //   step === MutationByMachineStep.WaitingForMachine
                //     ? 'visible'
                //     : 'hidden',
              }}
            ></div>
          </Grid>
        </Grid>
      ))}
    </>
  )
}
