import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useAuth } from 'src/shared/auth/context/authUser'

import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak'
import EqualizerIcon from '@mui/icons-material/EqualizerOutlined'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import LocationOnIcon from '@mui/icons-material/LocationOnOutlined'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { styled, useTheme } from '@mui/material/styles'

const PREFIX = 'LabelBottomNavigation'

const classes = {
  bottomNavigation: `${PREFIX}-bottomNavigation`,
  actionRootSelected: `${PREFIX}-actionRootSelected`,
  appBar: `${PREFIX}-appBar`,
  smallAvatar: `${PREFIX}-smallAvatar`,
  fabButton: `${PREFIX}-fabButton`,
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`& .${classes.bottomNavigation}`]: {
    // width: 500,
  },

  [`& .${classes.actionRootSelected}`]: {
    color: theme.palette.primary.main + '!important',
  },

  [`&.${classes.appBar}`]: {
    top: 'auto',
    bottom: 0,
  },

  [`& .${classes.smallAvatar}`]: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },

  [`& .${classes.fabButton}`]: {
    width: theme.spacing(8),
    height: theme.spacing(8),

    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
}))

export default function LabelBottomNavigation() {
  const { authState } = useAuth()
  const profile = authState.user
  const theme = useTheme()

  const location = useLocation()
  const value = location.pathname
  // const [value, setValue] = React.useState(window.location.pathname);

  const handleChange = () => {
    // setValue(newValue);
  }

  return (
    <StyledAppBar position="fixed" className={classes.appBar}>
      <BottomNavigation
        showLabels
        sx={{
          height: 72,
          // background: theme.palette.primary.main,
          // color: 'white',
          '& .MuiBottomNavigationAction-root': { color: 'inherit' },
        }}
        value={value}
        onChange={handleChange}
        className={classes.bottomNavigation}
      >
        <BottomNavigationAction
          classes={{
            selected: classes.actionRootSelected,
          }}
          component={NavLink}
          to="/leaderboards"
          label="排行榜"
          value="/leaderboards"
          icon={<EqualizerIcon />}
        />
        <BottomNavigationAction
          classes={{
            selected: classes.actionRootSelected,
          }}
          component={NavLink}
          to="/points"
          label="集點"
          value="/points"
          icon={<FavoriteBorderOutlinedIcon />}
        />
        <BottomNavigationAction
          // sx={{ color: 'while' }}
          classes={{
            selected: classes.actionRootSelected,
          }}
          component={NavLink}
          to="/"
          label="使用"
          value="/"
          icon={<CenterFocusWeakIcon />}
        />
        <BottomNavigationAction
          classes={{
            selected: classes.actionRootSelected,
          }}
          component={NavLink}
          to="/stores"
          label="地圖"
          value="/stores"
          icon={<LocationOnIcon />}
        />
        <BottomNavigationAction
          classes={{
            selected: classes.actionRootSelected,
          }}
          component={NavLink}
          to="/my-page"
          label="我的"
          value="/my-page"
          icon={
            <Avatar
              alt="Your head"
              src={profile?.picture_url}
              className={classes.smallAvatar}
            />
          }
        />
      </BottomNavigation>
    </StyledAppBar>
  )
}
