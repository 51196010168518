import { Typography, Button } from '@mui/material'
import { MutationType } from '../MutationType'
import { RentInput } from '../RentInput'

export default function ChoosingNumber({
  rentingNum,
  minNum,
  maxNum,
  mutationType,
  cupNumsToChoose,
  onNext,
  onCancel,
  rentOrReturnNumber,
  onRentOrReturnNumberChange,
  totalNumOfRents: totalNumberOfRentsToRentMany,
  rentsFormInput,
  setRentsFormInput,
}: {
  rentingNum?: number
  minNum: number
  maxNum: number
  mutationType?: MutationType
  cupNumsToChoose: any[]
  onNext: () => void
  onCancel: () => void
  rentOrReturnNumber: number
  onRentOrReturnNumberChange: (value: number) => void
  totalNumOfRents: number
  rentsFormInput: any[]
  setRentsFormInput: any
}) {
  const shouldUseRentManyForm = mutationType === MutationType.RENT

  return (
    <>
      <Typography variant="h6" align="center">
        {mutationType ?? ''}杯數
      </Typography>
      <Typography variant="body2" align="center">
        {mutationType === MutationType.RENT && ''}
        {mutationType === MutationType.RETURN &&
          `尚未歸還杯數：${rentingNum ?? '.'}杯`}
      </Typography>

      {mutationType === MutationType.RENT && (
        <>
          <Typography variant="body2" align="center">
            尚可借用：{maxNum}杯
          </Typography>
        </>
      )}

      {shouldUseRentManyForm ? (
        cupNumsToChoose.map((cn, index) => (
          <RentInput
            key={cn.id}
            mutationType={mutationType}
            cupImageUrl={cn.cup_type?.image_url}
            cupTypeName={cn.cup_type?.name}
            value={rentsFormInput[index].num ?? 0}
            onChange={(value) =>
              setRentsFormInput((prev) => {
                const newRents = [...prev]
                newRents[index].num = value
                return newRents
              })
            }
            minNum={cupNumsToChoose.length === 1 ? 1 : 0}
            maxNum={
              maxNum -
              totalNumberOfRentsToRentMany +
              (rentsFormInput[index].num ?? 0)
            }
          />
        ))
      ) : (
        <RentInput
          mutationType={mutationType}
          value={rentOrReturnNumber}
          onChange={onRentOrReturnNumberChange}
          minNum={minNum}
          maxNum={maxNum}
        />
      )}
      <Button
        size="large"
        variant="contained"
        fullWidth
        sx={{ my: 1 }}
        style={{
          lineHeight: 'inherit',
          textTransform: 'none',
        }}
        disabled={shouldUseRentManyForm && totalNumberOfRentsToRentMany === 0}
        onClick={onNext}
      >
        下一步
      </Button>
      <Button sx={{ my: 1 }} fullWidth color="inherit" onClick={onCancel}>
        取消
      </Button>
    </>
  )
}
