import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const PREFIX = 'LinearProgressWithLabel'

const classes = {
  container: `${PREFIX}-container`,
  root: `${PREFIX}-root`,
  progressBarBackground: `${PREFIX}-progressBarBackground`,
  progressBar: `${PREFIX}-progressBar`,
  label: `${PREFIX}-label`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.container}`]: {
    position: 'relative',
  },

  [`& .${classes.root}`]: {
    borderRadius: 10,
  },

  [`& .${classes.progressBarBackground}`]: {
    backgroundColor: '#F3F9FC',
  },

  [`& .${classes.progressBar}`]: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 10,
  },

  [`& .${classes.label}`]: {
    position: 'absolute',
    left: '50%',
    top: '50%',

    /* bring your own prefixes */
    transform: 'translate(-50%, -50%)',
    color: 'black',
  },
}))

function LinearProgressWithLabel({
  mn,
  value,
  mx,
  variant,
  height = 20,
  ...props
}) {
  const normalise = (value) => ((value - mn) * 100) / (mx - mn)

  return (
    <StyledBox className={classes.container} width="100%" mr={1}>
      <LinearProgress
        variant={variant}
        value={normalise(value)}
        classes={{
          root: classes.root,
          colorPrimary: classes.progressBarBackground,
          bar: classes.progressBar,
        }}
        sx={{ height: height }}
        {...props}
      />
      <div className={classes.label}>
        <Typography variant="body2">
          {mx === null ? '' : `${value}/${Math.round(mx)}`}
        </Typography>
      </div>
    </StyledBox>
  )
}

export default LinearProgressWithLabel
