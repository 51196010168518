import { differenceInHours } from 'date-fns'

const RentServices = {
  isOverDue(r) {
    return (
      r?.create_time &&
      differenceInHours(new Date(), new Date(r.create_time)) > 72
    )
  },
  isReturned(r) {
    return r?.rent_type === 'uCup' && r?.returns?.length !== 0
  },
}

export default RentServices
