import { useScript } from '@uidotdev/usehooks'
import { useEffect, useState } from 'react'

const APP_ID = process.env.REACT_APP_TAPPAY_APP_ID
const APP_KEY = process.env.REACT_APP_TAPPAY_APP_KEY
const SERVER_TYPE =
  process.env.NODE_ENV === 'production' ? 'production' : 'sandbox'
const SDK_URL = `https://js.tappaysdk.com/tpdirect/v5.1.0`

export function useTappay() {
  const status = useScript(SDK_URL, {
    removeOnUnmount: false,
  })

  /*
  cardType (String)	'mastercard', 'visa', 'jcb', 'amex', 'unknown'
  canGetPrime (boolean)	true = 全部欄位皆為正確，可以呼叫 getPrime
  hasError (boolean)	true = 任何欄位有錯誤
  status.number (int)	回傳的 status code 請參考 reference
  status.expiry (int)	回傳的 status code 請參考 reference
  status.ccv (int)	回傳的 status code 請參考 reference 
  */
  const [update, setUpdate] = useState<{
    cardType: string
    canGetPrime: boolean
    hasError: boolean
    status: {
      number: number
      expiry: number
      ccv: number
    }
  }>()

  const [setup, setSetup] = useState<boolean>(false)
  const [TPDirect, setTPDirect] = useState<any>()
  useEffect(() => {
    const TPDirect = (window as any).TPDirect
    if (TPDirect) {
      setTPDirect(TPDirect)
    }
    if (!setup && status === 'ready') {
      if (!APP_ID || !APP_KEY) {
        console.error('APP_ID or APP_KEY is not provided')
        return
      }
      if (!TPDirect.setupSDK) {
        console.error('TPDirect.setupSDK is not a function')
        return
      }

      TPDirect.setupSDK(APP_ID, APP_KEY, SERVER_TYPE)
      setSetup(true)

      const defaultCardViewStyle = {
        color: 'rgb(0,0,0)',
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: '300',
        errorColor: 'red',
        placeholderColor: '',
      }
      if (!TPDirect.card || !TPDirect.card.setup) {
        console.error('TPDirect.card.setup is not a function')
        return
      }

      // isUsedCcv default true
      TPDirect.card.setup('#cardview-container', defaultCardViewStyle)

      TPDirect.card.onUpdate(function (update) {
        setUpdate(update)
      })
    }
  }, [setup, status])

  return { status, TPDirect, update }
}
