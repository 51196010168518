import { useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { shareCupLink } from 'src/line/share'
import hasNoRecord from 'src/pages/hasNoRecord.png'
import HighWall from 'src/shared/dataProvider/HighWall'
import { useSnackbar } from 'src/shared/snackbar/context'

import liff from '@line/liff'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import shareRentIcon from './shareRentIcon.png'
import RentServices from 'src/rent/services'
import { format } from 'date-fns'
import { ListItemIcon, Checkbox, ListItemButton } from '@mui/material'
import { CupTypesService } from 'src/client'
import { highWallTimestampToDate } from 'src/utils/utils'

const PREFIX = 'RecordList'

const classes = {
  recordList: `${PREFIX}-recordList`,
  inline: `${PREFIX}-inline`,
  storeImg: `${PREFIX}-storeImg`,
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  [`& .${classes.recordList}`]: {
    overflow: 'scroll',
    backgroundColor: 'white',
  },

  [`& .${classes.inline}`]: {
    display: 'inline',
  },

  [`& .${classes.storeImg}`]: {
    height: 84,
    width: 84,
    borderRadius: theme.spacing(2),
    marginRight: theme.spacing(2),
    boxShadow: theme.spacing(0.25, 0.5, 0.25, 'lightgray'),
  },
}))

interface RecordEntity {
  id: number
  store_name?: string | null
  store_image_url?: string | null
  cup_id?: string | null
  rent_type?: string | null
  returns?: any[] | null
  provider?: string | null
  create_time?: string[] | string | null
  note?: string | null
  cup_type_id?: string
  cup_type?: {
    name?: string
  }
}

export default function RecordList({
  records,
  userId,
  showShareButton = true,
  selection = false,
  checkedIds: checkedIdsProp = undefined,
  onChange = () => undefined,
}: {
  records?: RecordEntity[]
  userId: string
  showShareButton?: boolean
  selection?: boolean
  checkedIds?: number[]
  onChange?: (ids: number[]) => void
}) {
  const cupTypesQuery = useQuery(
    ['cup_types'],
    CupTypesService.findAllApiCupTypesGet
  )
  const shareNum = 1
  const [checkedIdsInner, setCheckedIdsInner] = useState<number[]>([])
  const checkedIds = useMemo(
    () => checkedIdsProp ?? checkedIdsInner,
    [checkedIdsProp, checkedIdsInner]
  )
  const setCheckedIds = (value: number[]) => {
    setCheckedIdsInner(value)
    onChange(value)
  }

  const handleToggle = (value: number) => {
    const currentIndex = checkedIds.indexOf(value)
    const newChecked = [...checkedIds]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setCheckedIds(newChecked)
  }

  const handleItemClick = (id: number) => {
    handleToggle(id)
  }

  let isLastRent = true
  const { handleError } = useSnackbar()

  const createShareCodeMutation = useMutation<any, unknown, any>(
    HighWall.createShareCode,
    {
      onSuccess: (data) => {
        handleMutationSuccess(data.code)
      },
      onError: (data) => {
        handleError(data)
      },
    }
  )
  const handleMutationSuccess = (code) => {
    shareCupLink(liff, Date.now(), userId, shareNum, code)
  }

  const shouldShowShareButton =
    showShareButton && records?.some((r) => !RentServices.isReturned(r))

  if (!records || records.length === 0) {
    return (
      <div
        style={{
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 16,
          overflow: 'hidden',
        }}
      >
        <Typography variant="body2" color="textSecondary">
          尚無紀錄，快去使用點數兌換優惠吧！
        </Typography>
        <img src={hasNoRecord} alt="hasNoRecord"></img>
      </div>
    )
  }

  return (
    <>
      {shouldShowShareButton && (
        <div
          style={{
            width: '100%',
            padding: '0 16px',
            paddingTop: '16px',
          }}
        >
          <Button
            variant="text"
            color="inherit"
            disableRipple
            fullWidth
            startIcon={
              <img
                src={shareRentIcon}
                alt="shareRentIcon"
                width={35}
                height={35}
                style={{ objectFit: 'contain' }}
              />
            }
            sx={{
              boxShadow: 1,
              fontWeight: 1000,
              lineHeight: 1.5,
              p: 1,
            }}
            onClick={() => {
              createShareCodeMutation.mutate(shareNum)
            }}
            disabled={createShareCodeMutation.isLoading}
          >
            好心人代還杯
          </Button>
        </div>
      )}
      <List className={classes.recordList}>
        {records.map((record) => {
          const recordWithCupType = {
            ...record,
            cup_type: cupTypesQuery.data?.find(
              (cupType) => cupType.id === record.cup_type_id
            ),
          }

          return (
            <div key={record.id}>
              {(function () {
                const temp = isLastRent
                if (
                  record.rent_type === 'uCup' &&
                  record.returns?.length === 0 &&
                  isLastRent
                ) {
                  isLastRent = false
                }
                return (
                  <Record
                    key={record.id}
                    record={recordWithCupType}
                    userId={userId}
                    shareNum={shareNum}
                    isLastRent={temp}
                    selection={selection}
                    selected={checkedIds.indexOf(record.id) !== -1}
                    onItemClick={() => handleItemClick(record.id)}
                  />
                )
              })()}
              <Divider variant="inset" component="li" />
            </div>
          )
        })}
      </List>
    </>
  )
}

function Record({
  record,
  userId,
  shareNum,
  isLastRent,
  selection = false,
  selected = false,
  onItemClick = () => undefined,
}: {
  record: RecordEntity
  userId: string
  shareNum: number
  isLastRent: boolean
  selection?: boolean
  selected?: boolean
  onItemClick?: (record: RecordEntity) => void
}) {
  const theme = useTheme()

  const renderProvider = () => {
    if (record.provider === 'NFC') {
      return '學生證'
    } else if (record.provider === 'Normal') {
      // return '手機 QR Code'
      return '手機條碼'
    } else {
      return record.provider
    }
  }

  const getCreateTime = () => {
    if (!record.create_time) {
      return ''
    }

    const date =
      typeof record.create_time === 'string'
        ? new Date(record.create_time)
        : highWallTimestampToDate(record.create_time)
    if (!date) {
      return ''
    }
    return format(date, 'yyyy-MM-dd HH:mm')
  }
  const createTime = getCreateTime()

  if (!record) {
    return <></>
  }
  return (
    <StyledListItem
      alignItems="flex-start"
      sx={{
        px: 0,
      }}
    >
      <ListItemButton
        role={undefined}
        onClick={() => onItemClick(record)}
        dense
      >
        {selection && (
          <div
            style={{
              marginLeft: -8,
              flexGrow: 0,
              width: 32,
            }}
          >
            <ListItemIcon
              style={{}}
              sx={{
                flexGrow: 0,
              }}
            >
              <Checkbox
                edge="start"
                checked={selected}
                tabIndex={-1}
                disableRipple
                // inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
          </div>
        )}
        <ListItemAvatar
          sx={{
            flexGrow: 0,
          }}
        >
          <Avatar
            variant="rounded"
            className={classes.storeImg}
            alt={record.store_name ?? ''}
            key={record.store_name ?? ''}
            src={record.store_image_url ?? ''}
          />
        </ListItemAvatar>

        <ListItemText
          primary={record.store_name}
          secondary={
            <div
              style={{
                width: '100%',
              }}
            >
              {record?.rent_type === 'uCup' ? (
                record?.returns?.length === 0 ? (
                  <Typography
                    variant="body2"
                    component="p"
                    style={{
                      color: RentServices.isOverDue(record)
                        ? theme.palette.error.main
                        : theme.palette.primary.main,
                    }}
                  >
                    {RentServices.isOverDue(record) ? '已逾期' : '使用中'}
                  </Typography>
                ) : (
                  <Typography variant="body2">已歸還</Typography>
                )
              ) : (
                <Typography variant="body2">自備杯子</Typography>
              )}
              <div
                style={{
                  display: 'flex',
                }}
              >
                {record?.cup_id && (
                  <Typography variant="body2">杯子#{record.cup_id}</Typography>
                )}
                {record?.cup_type && (
                  <Typography variant="body2">
                    {record?.cup_type?.name}
                  </Typography>
                )}
              </div>
              <Typography variant="body2">{record.note}</Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography variant="body2">{renderProvider()}</Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{
                    fontSize: '0.75rem',
                  }}
                >
                  {`${createTime}`}
                </Typography>
              </div>
            </div>
          }
        />
      </ListItemButton>
    </StyledListItem>
  )
}
