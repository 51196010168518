import { Typography, Box, Button, styled } from '@mui/material'
import Grid from '@mui/material/Grid'
import { OverdueCard } from 'src/rent/components'
import Rules from '../Rules'

import rentCupSmall from './rent-cup-small.png'
import returnStationSmallImg from './return-station-small.png'

const StyledButton = styled(Button)(({ theme }) => ({
  lineHeight: 'inherit',
  background: 'white',
  color: 'black',
  textTransform: 'none',
  width: '100%',
  // borderRadius: theme.spacing(3),
  marginLeft: 0,
  marginRight: 0,
  padding: theme.spacing(0.5, 3),
  justifyContent: 'flex-start',
}))

export default function ChoosingMutationType({
  isOverDue,
  rentDisabled,
  onRentClick,
  returnDisabled,
  onReturnClick,
  onCancel,
}: {
  isOverDue: boolean
  rentDisabled: boolean
  onRentClick: () => void
  returnDisabled: boolean
  onReturnClick: () => void
  onCancel: () => void
}) {
  return (
    <div
      style={{
        margin: '8px 0',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      {isOverDue && (
        <div
          style={{
            marginBottom: 16,
          }}
        >
          <OverdueCard />
        </div>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledButton
            size="large"
            variant="contained"
            startIcon={
              <img
                src={rentCupSmall}
                alt="rentIcon"
                style={{
                  objectFit: 'contain',
                  marginLeft: 8,
                }}
                width={40}
                height={60}
              />
            }
            disabled={rentDisabled}
            onClick={onRentClick}
          >
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              我要借用 Use
            </Typography>
          </StyledButton>
        </Grid>
        <Grid item xs={12}>
          <StyledButton
            size="large"
            variant="contained"
            startIcon={
              <img
                src={returnStationSmallImg}
                alt="returnStationSmallImg"
                style={{
                  objectFit: 'contain',
                  marginLeft: 8,
                }}
                width={40}
                height={60}
              />
            }
            disabled={returnDisabled}
            onClick={onReturnClick}
          >
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              我要歸還 Return
            </Typography>
          </StyledButton>

          <Box sx={{ mt: 4 }}>
            <Rules />
          </Box>

          <Button
            sx={{ mt: 2 }}
            fullWidth
            // variant="outlined"
            color="inherit"
            onClick={onCancel}
          >
            取消
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
