import { encode as base64_encode } from 'base-64'
import { useAuth } from 'src/shared/auth/context/authUser'

import { Liff } from '@line/liff'

export default function share(liff: Liff, num: number | undefined) {
  if (liff.isApiAvailable('shareTargetPicker') && num) {
    liff
      .shareTargetPicker([
        {
          type: 'text',
          text: `我已經減少了${num}個免洗杯，而且還獲得額外集點，可以兌換調酒、飲料買一送一等優惠！快來用uCup平台，減塑愛地球！

加入uCup Line好友：https://lin.ee/iwQZTOv`,
        },
      ])
      .then(function (res) {
        if (res) {
          // succeeded in sending a message through TargetPicker
          console.log(`[${res.status}] Message sent!`)
        } else {
          const [majorVer, minorVer] = (liff.getLineVersion() || '').split('.')
          if (parseInt(majorVer) === 10 && parseInt(minorVer) < 11) {
            // LINE 10.3.0 - 10.10.0
            // Old LINE will access here regardless of user's action
            console.log(
              'TargetPicker was opened at least. Whether succeeded to send message is unclear'
            )
          } else {
            // LINE 10.11.0 -
            // sending message canceled
            console.log('TargetPicker was closed!')
          }
        }
      })
      .catch(function (error) {
        // something went wrong before sending a message
        console.log('something wrong happen', error)
      })
  }
}

export function shareCupLink(
  liff: Liff,
  date: number | undefined,
  userId: string | undefined,
  shareNum: number | undefined,
  shareCode: string | undefined
) {
  const newDate = date ?? ''
  const prefix = 'https://liff.line.me/'
  const encoded = base64_encode(
    newDate
      .toString()
      .concat(
        ' ',
        userId ?? '',
        ' ',
        (shareNum ?? '').toString(),
        ' ',
        (shareCode ?? '').toString()
      )
  )
  const link = prefix.concat(
    process.env.REACT_APP_MY_LIFF_ID ?? '',
    '?give-me-cup=',
    encoded
  )

  if (liff.isApiAvailable('shareTargetPicker') && date) {
    liff
      .shareTargetPicker([
        {
          type: 'text',
          text: `我已轉移uCup借用紀錄給您，若確定接收，請點擊下方此連結，借用紀錄即可轉移至您的系統中

          ${link}`,
        },
      ])
      .then(function (res) {
        if (res) {
          // succeeded in sending a message through TargetPicker
          console.log(`[${res.status}] Message sent!`)
        } else {
          const [majorVer, minorVer] = (liff.getLineVersion() || '').split('.')
          if (parseInt(majorVer) === 10 && parseInt(minorVer) < 11) {
            // LINE 10.3.0 - 10.10.0
            // Old LINE will access here regardless of user's action
            console.log(
              'TargetPicker was opened at least. Whether succeeded to send message is unclear'
            )
          } else {
            // LINE 10.11.0 -
            // sending message canceled
            console.log('TargetPicker was closed!')
          }
        }
      })
      .catch(function (error) {
        // something went wrong before sending a message
        console.log('something wrong happen', error)
      })
  }
}
