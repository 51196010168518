import rentCup from './rent-cup.png'
import ownCupImg from './own-cup.png'
import returnStationImg from './return-station.png'
import { MutationType } from './MutationType'
import { Typography } from '@mui/material'

export default function CupImg({
  mutationType = MutationType.RENT,
  src = undefined,
  cupTypeName = undefined,
  width = 80,
  height = 128,
}: {
  mutationType?: MutationType | undefined
  src?: string
  cupTypeName?: string
  width?: number
  height?: number
}) {
  function getMutationImg(type?: MutationType) {
    if (type === undefined) {
      return rentCup
    }

    return {
      [MutationType.RENT]: rentCup,
      [MutationType.RETURN]: returnStationImg,
      [MutationType.SELF]: ownCupImg,
    }[type]
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <img
        src={
          src && mutationType === MutationType.RENT
            ? src
            : getMutationImg(mutationType)
        }
        alt="mutationType"
        style={{ objectFit: 'contain' }}
        width={width}
        height={height}
      ></img>
      <Typography align="center">{cupTypeName}</Typography>
    </div>
  )
}
