import { getRentAggregateDocument } from 'src/pages/Index/query'
import RentServices from './services'
import { useQuery as useGqlQuery } from '@apollo/client'

export function useRentStatus(options) {
  const getRentAggregateResult = useGqlQuery(getRentAggregateDocument, options)
  const rentingNum =
    getRentAggregateResult.data?.renting_num_aggregate?.aggregate?.count
  const totalUsage =
    getRentAggregateResult.data?.total_usage_aggregate?.aggregate?.count

  const renting = Array.isArray(
    getRentAggregateResult?.data?.renting_num_aggregate?.nodes
  )
    ? getRentAggregateResult?.data?.renting_num_aggregate?.nodes
    : []

  const overdueRents = renting?.filter(RentServices.isOverDue) ?? []
  const isOverDue = overdueRents.length > 0

  return {
    getRentAggregateResult,
    rentingNum,
    totalUsage,
    renting,
    overdueRents,
    isOverDue,
  }
}
