import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  DialogActions,
  Button,
} from '@mui/material'
import { CUP_PRICE } from 'src/constants'

export default function ConfirmDialog({ mutationType, open, onClose, onOk }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{'確認' + mutationType}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography gutterBottom>
            1. 請於借用後72小時內歸還至合作店家、自助歸還站
          </Typography>
          <Typography gutterBottom>
            2. 逾期未歸還將停權帳號，直至歸還後得以解除
          </Typography>
          <Typography gutterBottom>
            3. 若杯子損毀、遺失，每一個杯子賠償 {CUP_PRICE} 元
          </Typography>
          <Typography gutterBottom>*按下確認後即無法取消</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
        <Button onClick={onOk} autoFocus>
          確認
        </Button>
      </DialogActions>
    </Dialog>
  )
}
